import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import Favicon from "react-favicon";
import App from './App'
import favicon from '../public/favicon.png';

window.onbeforeunload = function () {
    window.scrollTo(0, 0);
}

ReactDOM.render(
    <Fragment>
        <Favicon url={favicon}/>
        <App/>
    </Fragment>,
    document.getElementById('app')
);
