import React from 'react';
import $ from 'jquery';

import logo from '../../../public/logo.svg';
import makeWithLove from '../../../public/makeWithLove.svg';
import {useMediaQuery} from "react-responsive";
import PersonalData from "../personalData";

export default function Footer(props) {
    const mobileVersion = useMediaQuery({
        query: '(max-width: 1024px)'
    })
    const {setIsDisablePhone, isEnglish} = props;
    return (
        <footer>
            <PersonalData/>
            <div className='footer__block'>
                <img style={!mobileVersion ? {width: 200, height: 35, marginTop: -5} : {width: 200, height: 35, marginTop: 0}} src={logo}/>
                <p style={!mobileVersion ? {marginLeft: 5} : {}}>
                    &copy; 2020 Spirocooking<br/>{!isEnglish ? 'Все права защищены' : 'All rights reserved'}
                </p>
            </div>
            <div className='footer__block footer__block-socials'>
                <div>
                    {isEnglish && <a target='_blank' className='facebook' href={'//www.facebook.com/Spirocooking'}>
                        <svg width="13" height="24" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.775 0.762207H9.4281C7.94866 0.762207 6.52982 1.34991 5.48369 2.39603C4.43757 3.44215 3.84987 4.861 3.84987 6.34044V9.68738H0.50293V14.15H3.84987V23.0751H8.31245V14.15H11.6594L12.775 9.68738H8.31245V6.34044C8.31245 6.04455 8.42999 5.76078 8.63922 5.55156C8.84844 5.34233 9.13221 5.22479 9.4281 5.22479H12.775V0.762207Z" fill="#117592"/>
                        </svg>
                    </a>}
                    <a target='_blank' className='youtube' href={'//www.youtube.com/channel/UCt7wClowgs7SWlT6gW00bBQ/featured?view_as=subscriber'}>
                        <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M22.8557 1.27792C23.9445 1.58111 24.803 2.46903 25.0961 3.59518C25.6405 5.65256 25.6196 9.94058 25.6196 9.94058C25.6196 9.94058 25.6196 14.2069 25.0961 16.2643C24.803 17.3905 23.9445 18.2784 22.8557 18.5816C20.8665 19.123 12.9097 19.123 12.9097 19.123C12.9097 19.123 4.97379 19.123 2.96365 18.5599C1.87482 18.2567 1.01633 17.3688 0.723181 16.2427C0.199707 14.2069 0.199707 9.91893 0.199707 9.91893C0.199707 9.91893 0.199707 5.65256 0.723181 3.59518C1.01633 2.46903 1.89576 1.55945 2.96365 1.25626C4.95285 0.714844 12.9097 0.714844 12.9097 0.714844C12.9097 0.714844 20.8665 0.714844 22.8557 1.27792ZM10.376 5.97712L16.9927 9.91863L10.376 13.8601V5.97712Z" fill="#CD3146"/>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect x="0.199707" y="0.714844" width="25.42" height="18.4082" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                    {isEnglish && <a target='_blank' className='instagram' href={'//instagram.com/spirocooking'}>
                        <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.499 2.31463C14.5642 2.31463 14.9277 2.32634 16.1389 2.38296C19.25 2.52743 20.7031 4.0337 20.8447 7.18484C20.9002 8.41972 20.9107 8.79067 20.9107 11.9184C20.9107 15.0471 20.8992 15.4171 20.8447 16.6519C20.7022 19.8001 19.2528 21.3093 16.1389 21.4538C14.9277 21.5104 14.5661 21.5222 11.499 21.5222C8.43388 21.5222 8.07035 21.5104 6.86017 21.4538C3.74143 21.3084 2.29591 19.7953 2.15432 16.651C2.09884 15.4161 2.08736 15.0461 2.08736 11.9174C2.08736 8.7897 2.09979 8.41972 2.15432 7.18386C2.29687 4.0337 3.74622 2.52646 6.86017 2.38198C8.07131 2.32634 8.43388 2.31463 11.499 2.31463V2.31463ZM11.499 0.203125C8.38127 0.203125 7.99095 0.216792 6.76641 0.273411C2.59726 0.468649 0.280213 2.82908 0.0888796 7.08722C0.0324363 8.33772 0.019043 8.73601 0.019043 11.9174C0.019043 15.0988 0.0324363 15.4981 0.087923 16.7476C0.279256 21.0018 2.59248 23.3662 6.76546 23.5614C7.99095 23.618 8.38127 23.6317 11.499 23.6317C14.6168 23.6317 15.0081 23.618 16.2326 23.5614C20.398 23.3662 22.7207 21.0057 22.9092 16.7476C22.9656 15.4981 22.979 15.0988 22.979 11.9174C22.979 8.73601 22.9657 8.33772 22.9102 7.0882C22.7227 2.83786 20.4066 0.469625 16.2336 0.274387C15.0081 0.216792 14.6168 0.203125 11.499 0.203125V0.203125ZM11.499 5.90212C8.24351 5.90212 5.60406 8.59543 5.60406 11.9174C5.60406 15.2394 8.24351 17.9337 11.499 17.9337C14.7546 17.9337 17.394 15.2404 17.394 11.9174C17.394 8.59543 14.7546 5.90212 11.499 5.90212ZM11.499 15.8222C9.38577 15.8222 7.67238 14.0748 7.67238 11.9174C7.67238 9.76101 9.38577 8.01265 11.499 8.01265C13.6123 8.01265 15.3257 9.76101 15.3257 11.9174C15.3257 14.0748 13.6123 15.8222 11.499 15.8222ZM17.6275 4.2592C16.8659 4.2592 16.2489 4.88884 16.2489 5.66491C16.2489 6.44098 16.8659 7.07063 17.6275 7.07063C18.388 7.07063 19.0041 6.44098 19.0041 5.66491C19.0041 4.88884 18.388 4.2592 17.6275 4.2592Z" fill="#CD3146"/>
                        </svg>
                    </a>}
                </div>
                <p className={isEnglish ? 'english-30' : ''} onClick={() => {
                    $('.personal-data-wrapper').css({display: 'flex'});
                    $('body').css({overflowY: 'hidden'});

                }}>
                    {!isEnglish ? 'Политика обработки персональных данных' : 'Privacy policy'}
                </p>
            </div>
            <div className='footer__block footer__navigation'>
                <a onClick={() => {
                    setIsDisablePhone(true);
                    setTimeout(() => setIsDisablePhone(false), 4000)
                    !mobileVersion
                        ? $('html, body').animate({scrollTop: $('.food-menu-container').offset().top}, 2000)
                        : $('html, body').animate({scrollTop: $('.food-menu-mobile-container').offset().top}, 2000)
                }}>
                    {!isEnglish ? 'Меню' : 'Menu'}
                </a>
                <a href='/philosophy'>
                    {!isEnglish ? 'Философия' : 'Philosophy'}
                </a>
                <a href='/spiroblog'>
                    Spiro LIFE
                </a>
            </div>
            <div className='footer__block make-with-love' onClick={() => window.open('//iskra.st', '_blank') }>
                <img src={makeWithLove}/>
            </div>
        </footer>
    )
}
