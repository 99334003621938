import React from 'react';
import Slider from "react-slick";

import reviewerAnna from '../../../public/reviews/reviewer-anna.png';
import reviewerZhenya from '../../../public/reviews/reviewer-zhenya.png';
import reviewerAnnaMobile from '../../../public/annaReviewsMobile.png';
import instagramImage from '../../../public/reviews/instagram.svg';
import spiroSymbol from '../../../public/reviews/spiro-symbol-white.svg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useMediaQuery} from "react-responsive";

let reviewsMobile;

let prevOnClick;
let nextOnClick;

function PrevArrow(props) {
    if (reviewsMobile) return <div/>
    const { onClick } = props;
    nextOnClick = onClick
    return (
        <div className={'reviews-button reviews-button-left '} onClick={onClick}>
            <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 1L1 4.5L5 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );
}

function NextArrow(props) {
    if (reviewsMobile) return <div/>
    const { onClick } = props;
    prevOnClick = onClick
    return (
        <div className={'reviews-button reviews-button-right '} onClick={onClick}>
            <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5 4.5L1 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );
}

function Dot(props) {
    return (
        <div className={'reviews-dot'}></div>
    );
}


const settings = {
    dots: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 1500,
    prevArrow: <PrevArrow/>,
    nextArrow: <NextArrow/>,
    swipeToSlide: true,
    infinite: true,
    customPaging: (i) => {
        return (
            <a>
                <div className='reviews-dot'></div>
            </a>
        )},
    dotsClass: 'slick-dots',
    responsive: [{
        breakpoint: 1200,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
        }
    }, {
        breakpoint: 1024,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
        }
    }],
    className: 'reviews__carousel'
}

export default function Reviews(props) {
    reviewsMobile = useMediaQuery({
        query: '(max-width: 1024px)'
    })
    const {apiData} = props;
    const reviewsLength = apiData.reviews.length;
    return (
        <article className='reviews-container'>
            <h5 className='reviews-header'>Отзывы клиентов</h5>
            <Slider {...settings}>
                {
                    apiData.reviews.map(el => {
                        const reviewer = el.split('///');
                        return (
                            <div className='reviewer'>
                                <img className='reviewer__image' data-image={`/api/public/${reviewer[2]}`}/>
                                <img className='reviewer__spiro-symbol' src={spiroSymbol}/>
                                <h6 className='reviewer__name' dangerouslySetInnerHTML={{__html: reviewer[0]}}>

                                </h6>
                                <img className='instagram-image' src={instagramImage}/>
                                <p className='reviewer__text' dangerouslySetInnerHTML={{__html: reviewer[1]}}></p>
                            </div>
                        )
                    })
                }
                {/*<div className='reviewer'>*/}
                {/*    <img className='reviewer__image' data-image={!reviewsMobile ? reviewerAnna : reviewerAnnaMobile}/>*/}
                {/*    <img className='reviewer__spiro-symbol' src={spiroSymbol}/>*/}
                {/*    <h6 className='reviewer__name'>*/}
                {/*        Анна*/}
                {/*    </h6>*/}
                {/*    <img className='instagram-image' src={instagramImage}/>*/}
                {/*    <p className='reviewer__text'>«Все к&nbsp;этому придут, просто кто-то раньше».*/}
                {/*        Это было легко, интересно, а&nbsp;главное - вкусно!*/}
                {/*        Спасибо за&nbsp;бесценный опыт, помощь и&nbsp;проделанную работу!</p>*/}
                {/*</div>*/}
                {/*<div className='reviewer'>*/}
                {/*    <img className='reviewer__image' data-image={!reviewsMobile ? reviewerZhenya : reviewerAnnaMobile}/>*/}
                {/*    <img className='reviewer__spiro-symbol' src={spiroSymbol}/>*/}
                {/*    <h6 className='reviewer__name'>*/}
                {/*        Евгения*/}
                {/*    </h6>*/}
                {/*    <img className='instagram-image' style={{left: 294}} src={instagramImage}/>*/}
                {/*    <p className='reviewer__text'>Курс комплексный,*/}
                {/*        целостный и&nbsp;кажется неисчерпаемым, как и&nbsp;сами ребята, его создавшие.*/}
                {/*        Спасибо, вы сделали большую работу и&nbsp;сделали её качественно!</p>*/}
                {/*</div>*/}
                {/*<div className='reviewer'>*/}
                {/*    <img className='reviewer__image' data-image={!reviewsMobile ? reviewerAnna : reviewerAnnaMobile}/>*/}
                {/*    <img className='reviewer__spiro-symbol' src={spiroSymbol}/>*/}
                {/*    <h6 className='reviewer__name'>*/}
                {/*        Анна*/}
                {/*    </h6>*/}
                {/*    <img className='instagram-image' src={instagramImage}/>*/}
                {/*    <p className='reviewer__text'>«Все к этому придут, просто кто-то раньше».*/}
                {/*        Это было легко, интересно, а&nbsp;главное - вкусно!*/}
                {/*        Спасибо за&nbsp;бесценный опыт, помощь и&nbsp;проделанную работу!</p>*/}
                {/*</div>*/}
            </Slider>
        </article>
    )
}