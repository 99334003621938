import React, {Fragment, useEffect, useState} from 'react';

import dish from '../../../public/howItWorks/how-it-works-dish.png';
import fork from '../../../public/howItWorks/how-it-works-fork.png';
import spoon from '../../../public/howItWorks/how-it-works-spoon.png';
import leaf from '../../../public/howItWorks/leaf.png'
import schedule from '../../../public/howItWorks/shedule.png'
import {useMediaQuery} from "react-responsive";

const HowItWorksMain = (props) => {
    const {apiData} = props;
    return (
        <Fragment>
            <p className='how-it-works-text' dangerouslySetInnerHTML={{__html: apiData.how_it_works_title}}></p>
            <section className='how-it-works'>
                <section className='how-it-works__info'>
                    <div className='how-it-works__info-circle'>
                        <img className='how-it-works__fork' src={fork}/>
                        <img className='how-it-works__dish' src={dish}/>
                        <img className='how-it-works__spoon' src={spoon}/>
                    </div>
                    <p className='how-it-works__info-food' dangerouslySetInnerHTML={{__html: apiData.how_it_works_first_text}}>
                    </p>
                </section>
                <section className='how-it-works__info'>
                    <div className='how-it-works__info-circle'>
                        <img className='how-it-works__leaf' src={leaf}/>
                    </div>
                    <p className='how-it-works__info-superfood' dangerouslySetInnerHTML={{__html: apiData.how_it_works_second_text}}>
                    </p>
                </section>
                <section className='how-it-works__info'>
                    <div className='how-it-works__info-circle'>
                        <img className='how-it-works-schedule' src={schedule}/>
                    </div>
                    <p className='how-it-works__info-schelude' dangerouslySetInnerHTML={{__html: apiData.how_it_works_third_text}}>
                    </p>
                </section>
            </section>
        </Fragment>
    )
}

const HowItWorksMarquee = (props) => {
    const {apiData} = props;
    // const [text, setText] = useState('');
    // useEffect(() => {
    //     fetch('/api/how-it-works/marquee')
    //         .then(res => res.json())
    //         .then(data => setText(data[0].words))
    // }, []);
    // if (text === '') return <Fragment/>;
    // const text = 'Organic;Gluten;Free;Dairy Free;Plant Based;Non-GMO'
    const text = apiData.how_it_works_marquee_words;
    const parsedText = text.split(';');
    let readyText = '';
    for (let i = 0; i < 55; i++) {
        readyText += `&bull;&nbsp;&nbsp;&nbsp;${parsedText[i % parsedText.length]}&nbsp;&nbsp;&nbsp;`
    }
    return (
        <div className='how-it-works-marquee'>
                <span dangerouslySetInnerHTML={{__html: readyText}}>
                </span>
        </div>
    )
}

export default function HowItWorks(props) {
    const {apiData} = props;
    const howItWorksMobile = useMediaQuery({
        query: '(max-width: 992px)'
    })

    return (
            <article className='how-it-works-container'>
                <HowItWorksMain apiData={apiData}/>
                {
                    !howItWorksMobile && <HowItWorksMarquee apiData={apiData}/>
                }
            </article>
    )
}