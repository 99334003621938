import React, {useEffect, Fragment, useState} from 'react';
import $ from 'jquery';
import Slider from 'react-slick';
import 'react-dropdown/style.css';

import breakfastDish from '../../../public/foodMenu/dish-breakfast.jpg';
import breakfastDish2 from '../../../public/foodMenu/dishBreakfast2.png';
import breakfastDish3 from '../../../public/foodMenu/dishBreakfast3.jpg';

import FoodSet from "./foodSet";
import {useDishes} from "./hooks";

export default function FoodMenuMobile(props) {
    const [, todayDishes, setWeek, setDayOfWeek] = useDishes()

    const {isEnglish} = props;

    useEffect(() => {
        const currentWeek = $('.food-menu-mobile__current-week');
        const nextWeek = $('.food-menu-mobile__next-week');

        currentWeek.click(() => {
            currentWeek.css({ color: '#146957', borderBottom: '2px solid #146957', fontWeight: 'bold' });
            nextWeek.css({color: '#000', borderBottom: 'none', fontWeight: 'normal'});
            setWeek(1)
        })
        nextWeek.click(() => {
            nextWeek.css({ color: '#146957', borderBottom: '2px solid #146957', fontWeight: 'bold'});
            currentWeek.css({color: '#000', borderBottom: 'none', fontWeight: 'normal'});
            setWeek(2)
        })

        const breakfast = $('.food-menu-mobile__breakfast');
        const lunch = $('.food-menu-mobile__lunch');
        const dinner = $('.food-menu-mobile__dinner');
        const superset = $('.food-menu-mobile__superset');
        const friday = $('.food-menu-mobile__friday');

        breakfast.click(() => {
            breakfast.css({background: '#146957', border: '1px solid #146957', color: '#fff'});
            dinner.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            lunch.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            superset.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            friday.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            setDayOfWeek(1)
        });
        lunch.click(() => {
            lunch.css({background: '#146957', border: '1px solid #146957', color: '#fff'});
            dinner.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            breakfast.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            superset.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            friday.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            setDayOfWeek(2)
        });
        dinner.click(() => {
            dinner.css({background: '#146957', border: '1px solid #146957', color: '#fff'});
            breakfast.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            lunch.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            superset.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            friday.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            setDayOfWeek(3)
        });
        superset.click(() => {
            superset.css({background: '#146957', border: '1px solid #146957', color: '#fff'});
            dinner.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            lunch.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            breakfast.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            friday.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            setDayOfWeek(4)
        });
        friday.click(() => {
            friday.css({background: '#146957', border: '1px solid #146957', color: '#fff'});
            dinner.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            lunch.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            breakfast.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            superset.css({background: '#fff', border: '1px solid #ebebeb', color: '#1d1d1d'});
            setDayOfWeek(5)
        });
    }, []);

    const settingTimes = {
        className: 'food-menu-mobile__times',
        swipeToSlide: true,
        slidesToShow: 4,
        infinite: false,
        // edgeFriction: -2
    }
    const settingDish = {
        className: 'food-menu-mobile__dishes',
        swipeToSlide: true,
        slidesToShow: 3,
        infinite: true,
        responsive: [
            {
                breakpoint: 555,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    }

    const dishLangName = !isEnglish ? 'name' : 'en_name';
    const dishLangDescription = !isEnglish ? 'description' : 'en_description';

    return (
        <section className='food-menu-mobile-container'>
            <article className='food-menu-mobile'>
                <h5 className='food-menu-mobile__menu-text'>{!isEnglish ? 'Меню' : 'Menu'}</h5>
                <div className='food-menu-mobile__weeks'>
                    <p className='food-menu-mobile__current-week'>{!props.isEnglish ? 'Текущая неделя' : 'This week'}</p>
                    <p className='food-menu-mobile__next-week'>{!props.isEnglish ? 'Текущая' : 'Next week'}</p>
                </div>
                <Slider {...settingTimes}>
                    <div className='food-menu-mobile__breakfast'>{!props.isEnglish ? 'ПН' : 'MO'}</div>
                    <div className='food-menu-mobile__lunch'>{!props.isEnglish ? 'ВТ' : 'TU'}</div>
                    <div className='food-menu-mobile__dinner'>{!props.isEnglish ? 'СР' : 'WE'}</div>
                    <div className='food-menu-mobile__superset'>{!props.isEnglish ? 'ЧТ' : 'TH'}</div>
                    <div className='food-menu-mobile__friday'>{!props.isEnglish ? 'ПТ' : 'FR'}</div>
                </Slider>
                <Slider {...settingDish}>
                    {todayDishes.map((dish) => <FoodSet
                        key={dish.id}
                        image={dish.photo}
                        description={<Fragment>{dish[dishLangName].toUpperCase()}{(dish[dishLangDescription] || '').length > 31 ? ' ' : <br/>}<span>{dish[dishLangDescription] || ''}</span></Fragment>}
                        className={'food-menu__dish'}
                    />)}
                    {
                        todayDishes.length < 3
                            && todayDishes.map((dish) => <FoodSet
                                key={dish.id + 2}
                                image={dish.photo}
                                description={<Fragment>{dish[dishLangName].toUpperCase()}{(dish[dishLangDescription] || '').length > 31 ? ' ' : <br/>}<span>{dish[dishLangDescription] || ''}</span></Fragment>}
                                className={'food-menu__dish'}
                            />)
                    }
                </Slider>
            </article>
        </section>
    )
}
