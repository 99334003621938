async function postData(url = '', data = {}, json = true, config = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: json ? JSON.stringify(data) : data, // body data type must match "Content-Type" header
        ...config
    });
    return await response.json(); // parses JSON response into native JavaScript objects
}

async function putData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return await response.json(); // parses JSON response into native JavaScript objects
}

async function deleteData(url = '') {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer' // no-referrer, *client
    });
    return await response.json(); // parses JSON response into native JavaScript objects
}

async function getData(url) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
    });
    return await response.json(); // parses JSON response into native JavaScript objects
}

export const auth = async (login, password) => {
    const result = await postData('/api/auth', { login, password })
    return result.status === true
}

export const isAdmin = async () => {
    const result = await getData('/api/auth')
    return result.data === true
}

export const subscribe = async (email) => {
    const result = await postData('/api/email/subscribe', { email })
    return result.data === true
}

export const subscribePackage = async (user) => {
    const result = await postData('/api/email/package', { user })
    return result.data === true
}

export const listPosts = async () => {
    const result = await getData('/api/article/latest')
    return result.data || {}
}

export const listPostsByCategory = async (category, page = 1) => {
    const result = await getData(`/api/article/category/${category}?page=${page}`)
    return result.data || {}
}

export const getLastPosts = async () => {
    const result = await getData('/api/article/last')
    return result.data || {}
}

export const searchPosts = async (query) => {
    const result = await getData(`/api/article/find?query=${query}`)
    return result.data || {}
}

export const getArticle = async (slug) => {
    const result = await getData(`/api/article/get/${slug}`)
    return result.data || null
}

export const createArticle = async (category, title) => {
    const result = await putData('/api/article', { category, title })
    return result.data
}

export const saveArticle = async (article) => {
    const result = await postData('/api/article', { article })
    return result.data
}

export const removeArticle = async (id) => {
    const result = await deleteData(`/api/article/${id}`)
    return result.status
}

export const uploadImage = async (photo) => {
    const formData = new FormData()
    formData.append('photo', photo)
    const result = await postData(`/api/article/upload`, formData, false, {
        headers: {}
    })
    return result.data || null
}

export const uploadImagePhilosophy = async (photo, name) => {
    const formData = new FormData()
    formData.append('photo', photo, name)
    const result = await postData(`/api/article/philosophy/upload`, formData, false, {
        headers: {}
    })
    return result.data || null
}

export const getMeals = async () => {
    const result = await getData('/api/meal')
    return result.data || {}
}
