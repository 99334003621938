import React from 'react';
import {useMediaQuery} from "react-responsive";

export default function YourName() {
    const subscribeFormMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });
    return (
        <div className='subscribe-form__line' style={subscribeFormMobile ? {height: 75, opacity: 1, paddingBottom: 0, borderBottom: 'none', marginTop: -15} : {height: 71, opacity: 1, paddingBottom: 10}}>
            {
                !subscribeFormMobile
                    ? <h6 className='subscribe-form__title'>Ваше<br/>имя</h6>
                    : <h6 className='subscribe-form__title' style={{top: 55}}>Ваше имя</h6>
            }
            <input className='subscribe-form__your-name' placeholder='Укажите ваше имя' type='text'/>
        </div>
    )
}