import React, {useEffect} from 'react';

import $ from "jquery";
import {useMediaQuery} from "react-responsive";

export default function Sex(props) {
    const {mark, isEnglish} = props;

    const mobileVersion = useMediaQuery({
        query: '(max-width: 992px)'
    });

    let isClicked;

    useEffect(() => {
        $('.subscribe-form__woman').click(() => {
            if (isClicked) return;
            isClicked = true;
            setTimeout(() => isClicked = false, 400);
            $('.subscribe-form__line h6, .subscribe-form__line span, .subscribe-form__header, .subscribe-form__about-delivery, .subscribe-form__set-date .Dropdown-control').css({color: '#fff'});
            document.getElementsByClassName('subscribe-form-container')[0].classList.remove('subscribe-form-container-man');
            document.getElementsByClassName('subscribe-form-container')[1].classList.remove('subscribe-form-container-man');
            $('.subscribe-form').css({background: '#006d57', color: '#fff'});
            $('.subscribe-form__circle').addClass('subscribe-form__circle_transparent')
            $('.subscribe-form__rectangle').css({border: '1px solid #fff', background: 'transparent'});
            $('.subscribe-form__woman .subscribe-form__checked-mark').css({opacity: 1, width: 28, height: 20});
            $('.subscribe-form__woman .subscribe-form__checked-mark').animate({opacity: 1, width: 14, height: 10}, 100);
            $('.subscribe-form__man .subscribe-form__checked-mark').css({opacity: 0});
            $('.subscribe-form__set-date').css({border: '1px solid #fff', color: '#fff'})
            document.getElementsByClassName('subscribe-form__subscribe-button')[0]
                    .classList.add('subscribe-form__subscribe-button-seted-sex');
            document.getElementsByClassName('subscribe-form__subscribe-button')[1]
                    .classList.add('subscribe-form__subscribe-button-seted-sex');
            $('.subscribe-form__set-date .Dropdown-arrow').css({'border-color': '#fff transparent'});
            $('.subscribe-form').addClass('select-woman');
            $('.subscribe-form').removeClass('select-man');

            $('.subscribe-form__input-woman').prop('checked', true);
        })
        $('.subscribe-form__man').click(() => {
            if (isClicked) return;
            isClicked = true;
            setTimeout(() => isClicked = false, 400);
            $('.subscribe-form__line h6, .subscribe-form__line span, .subscribe-form__header, .subscribe-form__about-delivery, .subscribe-form__set-date .Dropdown-control').css({color: '#fff'});
            document.getElementsByClassName('subscribe-form-container')[0].classList.add('subscribe-form-container-man');
            document.getElementsByClassName('subscribe-form-container')[1].classList.add('subscribe-form-container-man');
            $('.subscribe-form').css({background: '#007597', color: '#fff'});
            $('.subscribe-form__circle').addClass('subscribe-form__circle_transparent')
            $('.subscribe-form__rectangle').css({border: '1px solid #fff', background: 'transparent'});
            $('.subscribe-form__man .subscribe-form__checked-mark').css({opacity: 1, width: 28, height: 20});
            $('.subscribe-form__man .subscribe-form__checked-mark').animate({opacity: 1, width: 14, height: 10}, 100);
            $('.subscribe-form__woman .subscribe-form__checked-mark').css({opacity: 0});
            $('.subscribe-form__set-date').css({border: '1px solid #fff', color: '#fff'})
            document.getElementsByClassName('subscribe-form__subscribe-button')[0]
                .classList.add('subscribe-form__subscribe-button-seted-sex');
            document.getElementsByClassName('subscribe-form__subscribe-button')[1]
                .classList.add('subscribe-form__subscribe-button-seted-sex');
            $('.subscribe-form__set-date .Dropdown-arrow').css({'border-color': '#fff transparent'});
            $('.subscribe-form').addClass('select-man');
            $('.subscribe-form').removeClass('select-woman');
            $('.subscribe-form__input-man').prop('checked', true);
        })
    }, [])

    return (
        <div className='subscribe-form__line' style={!mobileVersion ? {height: 45, paddingTop: 5, paddingBottom: 10} : {height: 45, borderBottom: 'none'}}>
            <h6 className='subscribe-form__title' style={
                mobileVersion
                    ? {top: 57}
                    : {}
            }>{!isEnglish ? 'Пол' : 'Sex'}</h6>
            <label className='subscribe-form__label subscribe-form__man' style={
                !mobileVersion
                    ? {width: 49, height: 27, marginLeft: 15, top: 0}
                    : {width: 38.61, height: 21, top: 5}
            }>
                <div className='subscribe-form__circle'>
                    <img className='subscribe-form__checked-mark' src={mark} />
                </div>
                <span>М</span>
                <input className='subscribe-form__input-man' type='radio' name='sex' value='man' />
            </label>
            <label className='subscribe-form__label subscribe-form__woman' style={
                !mobileVersion
                    ? {width: 49, height: 27, marginLeft: 71, top: 0}
                    : {width: 39, height: 20, marginLeft: '7%', top: 5}
            }>
                <div className='subscribe-form__circle'>
                    <img className='subscribe-form__checked-mark' src={mark}/>
                </div>
                <span>{!isEnglish ? 'Ж' : 'F'}</span>
                <input className='subscribe-form__input-woman' type='radio' name='sex' value='woman'/>
            </label>
        </div>
    )
}
