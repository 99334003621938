import React, {Fragment, useEffect, useState} from 'react';
import $ from 'jquery';
import './style.css';

import beauty1 from '../../../public/superset/BEAUTY 1.jpg';
import beauty2 from '../../../public/superset/BEAUTY 2.jpg';
import beauty3 from '../../../public/superset/BEAUTY 3.jpg';
import beauty4 from '../../../public/superset/BEAUTY 4.jpg';
import beauty5 from '../../../public/superset/BEAUTY 5.jpg';
import perf1 from '../../../public/superset/PERF 1.jpg';
import perf2 from '../../../public/superset/PERF 2.jpg';
import perf3 from '../../../public/superset/PERF 3.jpg';
import perf4 from '../../../public/superset/PERF 4.jpg';
import perf5 from '../../../public/superset/PERF 5.jpg';

import Element from "./element";
import {useMediaQuery} from "react-responsive";
import Slider from 'react-slick';

const settingElements = {
    className: 'superset-mobile__elements',
    swipeToSlide: true,
    slidesToShow: 4,
    infinite: true,
    responsive: [
        {
            breakpoint: 555,
            settings: {
                slidesToShow: 2
            }
        }
    ]
}

let SUPERSET_TEXT;

let COUNT = 1;

let IS_BLOCKED;

const clickLeft = (first, second, secondImage, third) => {
    if (IS_BLOCKED) return;
    IS_BLOCKED = true;
    setTimeout(() => IS_BLOCKED = false, 1200);
    $('.superset__button-left.superset__buttons').css({opacity: 1, cursor: 'pointer'});
    $(first).animate({left: -500}, 1000);
    $(second).animate({left: 0, width: 416.5, height: 501, top: 0}, 1000)
    $(secondImage).animate({width: 418, height: 418}, 1000);
    $(second).css({filter: 'blur(0)', zIndex: 10});
    $(first).css({zIndex: 100});
    $(third).css({zIndex: 0});
    if (third.length) {
        $(third).animate({left: 120}, 1000);
        COUNT++;
    }
    if (third.length === 0) {
        $('.superset__button-right.superset__buttons').css({opacity: .5, cursor: 'unset'});
        $('.superset__button-left.superset__buttons').css({opacity: 1, cursor: 'pointer'});
    }
}

const clickRight = (second, first, secondImage, third) => {
    if (IS_BLOCKED) return;
    IS_BLOCKED = true;
    setTimeout(() => IS_BLOCKED = false, 1200);
    $('.superset__button-left.superset__buttons').css({opacity: 1, cursor: 'pointer'});
    $(second).css({filter: 'blur(2px)'});
    $(first).animate({left: 0}, 1000);
    $(second).animate({left: 120, width: 368.5, height: 443, top: 30}, 1000);
    $(secondImage).animate({width: 370, height: 370}, 1000);
    $(second).css({zIndex: 10});
    $(first).css({zIndex: 100});
    $(third).css({zIndex: 0});
    if (COUNT === 4) $('.superset__button-right.superset__buttons').css({opacity: 1, cursor: 'pointer'});
    if (third.length) {
        $(third).animate({left: 600, width: 368.5, height: 443, top: 30}, 1000);
        $(third).find('.superset__image').animate({width: 370, height: 370}, 1000);
        COUNT--;
    }
    if (COUNT === 1) {
        $('.superset__button-left.superset__buttons').css({opacity: .5, cursor: 'unset'});
        $('.superset__button-right.superset__buttons').css({opacity: 1, cursor: 'pointer'});
    }
}

export default function Superset(props) {
    const [isBeauty, setIsBeauty] = useState(true);
    const {apiData} = props;
    const appMobile = useMediaQuery({
        query: '(max-width: 992px)'
    });

    useEffect(() => {
        $('.superset__beauty-button').click(function () {
            $(this).addClass('superset__button-is-active');
            $('.superset__performance-button').removeClass('superset__button-is-active');
            setIsBeauty(true);
        });
        $('.superset__performance-button').click(function () {
            $(this).addClass('superset__button-is-active');
            $('.superset__beauty-button').removeClass('superset__button-is-active');
            setIsBeauty(false);
        });
        $('.superset__button-left').click(() => {
            COUNT === 2
                ? clickRight('.superset__second-element', '.superset__first-element', '.superset__second-element .superset__image', '.superset__third-element')
                : COUNT === 3
                ? clickRight('.superset__third-element', '.superset__second-element', '.superset__third-element .superset__image', '.superset__fourth-element')
                : COUNT === 4
                    ? clickRight('.superset__fourth-element', '.superset__third-element', '.superset__fourth-element .superset__image', '.superset__fifth-element')
                    : ''
        })
        $('.superset__button-right').click(() => {
            COUNT === 1
                ? clickLeft('.superset__first-element', '.superset__second-element', '.superset__second-element .superset__image', '.superset__third-element')
                : COUNT === 2
                ? clickLeft('.superset__second-element', '.superset__third-element', '.superset__third-element .superset__image', '.superset__fourth-element')
                : COUNT === 3
                    ? clickLeft('.superset__third-element', '.superset__fourth-element', '.superset__fourth-element .superset__image', '.superset__fifth-element')
                    : COUNT === 4
                        ? clickLeft('.superset__fourth-element', '.superset__fifth-element', '.superset__fifth-element .superset__image', '')
                        : ''
        })
    }, []);
    if (isBeauty) {
        SUPERSET_TEXT = JSON.parse(apiData.superset_beauty);
    } else {
        SUPERSET_TEXT = JSON.parse(apiData.superset_performance);
    }

    const images = isBeauty
        ? [beauty1, beauty2, beauty3, beauty4, beauty5]
        : [perf1, perf2, perf3, perf4, perf5];

    const russianText = isBeauty
        ? ['Сок&nbsp;«‎омоложение»‎ &bull;&nbsp;Комбуча с&nbsp;зеленым чаем &bull;&nbsp;Шот&nbsp;«‎здоровье&nbsp;кожи»‎',
            'Сок&nbsp;«‎восстановление»‎ &bull;&nbsp;Комбуча с&nbsp;мятой и&nbsp;мелисой &bull;&nbsp;Шот «‎здоровье&nbsp;лица»‎',
            'Сок&nbsp;«‎сияние»‎ &bull;&nbsp;Комбуча с&nbsp;цитрусовыми &bull;&nbsp;Шот&nbsp;«‎омоложение»‎',
            'Сок&nbsp;«‎тонизирование»‎ &bull;&nbsp;Комбуча с&nbsp;шиповником и&nbsp;каркаде &bull;&nbsp;Шот «‎здоровье&nbsp;сосудов»‎',
            'Сок&nbsp;«‎очищение»‎ &bull;&nbsp;Комбуча куркумой и&nbsp;имбирем &bull;&nbsp;Шот&nbsp;«‎ощелачивание»‎'
        ]
        : [
            'Сок «‎тонус»‎ &bull;&nbsp;Комбуча с&nbsp;зеленым чаем &bull;&nbsp;Шот&nbsp;«‎пробуждение»‎',
            'Сок «‎иммунитет»‎ Комбуча с&nbsp;мятой и&nbsp;мелисой &bull;&nbsp;Шот&nbsp;«‎концентрация»‎',
            'Сок «‎внимание»‎ &bull;&nbsp;Комбуча с&nbsp;цитрусовыми &bull;&nbsp;Шот&nbsp;«‎движение»‎',
            'Сок «‎дыхание»‎ &bull;&nbsp;Комбуча с&nbsp;шиповником и&nbsp;каркаде &bull;&nbsp;Шот&nbsp;«‎иммунитет»‎',
            'Сок «‎межклеточное очищение»‎ &bull;&nbsp;Комбуча куркумой и&nbsp;имбирем &bull;&nbsp;Шот&nbsp;«‎сердце»‎'
        ]

    const englishText = isBeauty
        ? ['Juice "rejuvenation" &bull;&nbsp;Kombucha with&nbsp;green tea &bull;&nbsp;Shot "skin&nbsp;health"',
            'Juice "recovery" &bull;&nbsp;Kombucha with&nbsp;mint and&nbsp;lemon balm &bull;&nbsp;Shot "face&nbsp;health"',
            'Juice "radiance" &bull;&nbsp;Kombucha with&nbsp;citrus &bull;&nbsp;Shot "rejuvenation"',
            'Juice "toning" &bull;&nbsp;Kombucha with&nbsp;rose hip and&nbsp;hibiscus &bull;&nbsp;Shot "vascular&nbsp;health"',
            'Juice "cleansing" &bull;&nbsp;Kombucha with&nbsp;turmeric and&nbsp;ginger &bull;&nbsp;Shot «alkalinization»'
        ]
        : [
            'Juice "tonus" &bull;&nbsp;Kombucha with&nbsp;green tea &bull;&nbsp;Shot "awakening"',
            'Juice "immunity" &bull;&nbsp;Kombucha with&nbsp;mint and&nbsp;lemon balm &bull;&nbsp;Shot "concentration"',
            'Juice «attention» &bull;&nbsp;Kombucha with&nbsp;citrus &bull;&nbsp;Shot «movement»',
            'Juice «breath» &bull;&nbsp;Kombucha with&nbsp;rose hip and&nbsp;hibiscus &bull;&nbsp;Shot «immunity»',
            'Juice «intercellular cleansing» &bull;&nbsp;Kombucha with&nbsp;turmeric and&nbsp;ginger &bull;&nbsp;Shot "heart"'
        ]

    const text = !apiData.isEnglish ? russianText : englishText;

    return (
        <section className='superset-container'>
            <div className='superset'>
                <h5 className='superset__title'>{!apiData.isEnglish ? 'Суперсеты' : 'Supersets'}</h5>
                <svg className={`superset__title_svg ${apiData.isEnglish ? 'superset__title-eng-svg' : ''}`} width="159" height="5" viewBox="0 0 159 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 4C33.8805 2.43156 111.313 -0.234777 158 1.64735" stroke="#146957" stroke-width="2"/>
                </svg>
                <p className='superset__paragraph' dangerouslySetInnerHTML={{__html: apiData.superset_title}}>
                </p>
                <div className='superset__description'>
                    <div className='superset__beauty-button superset__button-is-active'>
                        BEAUTY
                    </div>
                    <div className='superset__performance-button'>
                        PERFORMANCE
                    </div>
                    <p className='superset__description-title' dangerouslySetInnerHTML={{__html: SUPERSET_TEXT.title}}>
                    </p>
                    <ul className='superset__description-text'>
                        <li style={{color: '#146957'}}>
                            <span dangerouslySetInnerHTML={{__html: SUPERSET_TEXT.first}} style={{color: '#1d1d1d'}}></span>
                        </li>
                        <li style={{color: '#117592'}}>
                            <span dangerouslySetInnerHTML={{__html: SUPERSET_TEXT.second}} style={{color: '#1d1d1d'}}></span>
                        </li>
                        <li style={{color: '#F3B03E'}}>
                            <span dangerouslySetInnerHTML={{__html: SUPERSET_TEXT.third}} style={{color: '#1d1d1d'}}></span>
                        </li>
                    </ul>
                </div>
                {
                    !appMobile ? <div className='superset__elements'>
                        <Element text={text[0]} image={images[0]} number='superset__first-element'/>
                        <Element text={text[1]} image={images[1]} number='superset__second-element'/>
                        <Element text={text[2]} image={images[2]} number='superset__third-element'/>
                        <Element text={text[3]} image={images[3]} number='superset__fourth-element'/>
                        <Element text={text[4]} image={images[4]} number='superset__fifth-element'/>
                    </div> : <Slider {...settingElements}>
                        {
                            images.map((el, i) => {
                                return (
                                    <Element text={text[i]} image={el} number='superset__first-element'/>
                                )
                            })
                        }
                    </Slider>
                }
                <div className='superset__button-left superset__buttons'>
                    <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 1L1 8.5L10 16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <div className='superset__button-right superset__buttons'>
                    <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L10 8.5L1 16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <div className='superset__wall'/>
                <div className='superset__wall-right'/>
            </div>
        </section>
    )
}
