import $ from "jquery";

let ts;
let event = {
    deltaY: 0
}

let scrollLikeSliderPhones;

function touchStart (e){
    ts = e.originalEvent.touches[0].clientY;
}

function touchEnd (e){
    let te = e.originalEvent.changedTouches[0].clientY;
    if(ts > te+5){
        event.deltaY = 1;
    }else if(ts < te-5){
        event.deltaY = -1;
    }
    scrollLikeSliderPhones(event)

}

export default function swipePhones(scrollLikeSlider, isActive) {
    scrollLikeSliderPhones = scrollLikeSlider;

    if (isActive) {
        $(document).bind('touchstart', touchStart);
        $(document).bind('touchend', touchEnd);
    } else {
        $(document).unbind('touchstart', touchStart);
        $(document).unbind('touchend', touchEnd);
    }


}