import React from "react";
import $ from "jquery";
import resetPositions from "../resetPositions";

function Monday(props) {

    const changeActivity = () => {
        $('.food-menu__monday .food-menu__user-choice').css({opacity: 1});
        $('.food-menu__monday span').css({fontWeight: 'bold'});

        $('.food-menu__friday .food-menu__user-choice').css({opacity: 0});
        $('.food-menu__friday span').css({fontWeight: 'normal'});
        $('.food-menu__tuesday .food-menu__user-choice').css({opacity: 0});
        $('.food-menu__tuesday span').css({fontWeight: 'normal'});
        $('.food-menu__wednesday .food-menu__user-choice').css({opacity: 0});
        $('.food-menu__wednesday span').css({fontWeight: 'normal'});
        $('.food-menu__thursday .food-menu__user-choice').css({opacity: 0});
        $('.food-menu__thursday span').css({fontWeight: 'normal'});

        props.setDayOfWeek && props.setDayOfWeek(1);

        resetPositions();

        // $('.food-menu__dish *').css({opacity: 0});
        // setTimeout(() => {
        //     setTimeout(() => $('.food-menu__dish *').css({opacity: 1}), 10);
        // }, 0);
    }

    return (
        <div className='food-menu__monday' onClick={changeActivity} ref={props.forwardedRef}>
            <div style={{opacity: 0}} className='food-menu__user-choice'/>
            <span style={{fontWeight: 'bold'}}>{!props.isEnglish ? 'Понедельник' : 'Monday'}</span>
        </div>
    )
}

export default React.forwardRef((props, ref) => <Monday {...props} forwardedRef={ref} />)
