import React, {useState, useEffect, Fragment} from 'react';
import $ from 'jquery';
import {useMediaQuery} from "react-responsive";
import SmoothScroll from '../../libs/scrollSmooth';

import renderMobilePhone from "./renderMobilePhone";
import swipePhones from "./swipe";

import nightMobile from '../../../public/phones/phonesNightMobile.png';
import phone0 from '../../../public/phones/phone0.png';
import phone1 from '../../../public/phones/phone1.png';
import phone2 from '../../../public/phones/phone2.png';
import phone3 from '../../../public/phones/phone3.png';
import phone4 from '../../../public/phones/phone4.png';
import phone5 from '../../../public/phones/phone5.png';
import phone5eng from '../../../public/phones/phone5eng.png'
import phone1eng from '../../../public/phones/phone1eng.png'
import phone2eng from '../../../public/phones/phone2eng.png'
import phone3eng from '../../../public/phones/phone3eng.png'
import phone4eng from '../../../public/phones/phone4eng.png'
import phone6eng from '../../../public/phones/phone6eng.png'
import one from '../../../public/phones/1.svg';
import two from '../../../public/phones/2.svg';
import three from '../../../public/phones/3.svg';
import four from '../../../public/phones/4.svg';
import five from '../../../public/phones/5.svg';
import opacityPhone from '../../../public/phonesOpacity.png'

import phoneFirst from '../../../public/phones/phones-first-image.png'
import phoneSecond from '../../../public/phones/phones-second-image.png'
import phoneThird from '../../../public/phones/phones-third-image.png'
import phoneFourth from '../../../public/phones/phones-fourth-image.png'
import mobileApp from '../../../public/mobile-app.png'

import {LoaderPromisePhone} from "../pack/loaderPromise";

let canNext = true;
let fixScreen;
let fixScreenDown

const isUpdatePhone = {
    isFirst: false,
    isSecond: false,
    isThird: false,
    isFourth: false,
    isFifth: false,
    isSixth: false
}

const getIsUpdatePhone = () => isUpdatePhone;

export default function Phones(props) {

    const [isLoading, setIsLoading] = useState(true);

    const phonesMobile = useMediaQuery({
        query: '(max-width: 992px)'
    })

    const {getIsDisablePhone, getPackRequestId, apiData} = props;

    useEffect(() => {

        !phonesMobile && isLoading && LoaderPromisePhone().then(value => {
            const [iphoneImage1, iphoneImage2, iphoneImage3, iphoneImage4, iphoneImage5, iphoneImage6, objIphone] = value;
            setIsLoading(false);
            setTimeout(() => {
                renderMobilePhone(getIsUpdatePhone,
                    iphoneImage1,
                    iphoneImage2,
                    iphoneImage3,
                    iphoneImage4,
                    iphoneImage5,
                    iphoneImage6,
                    objIphone,
                    getIsDisablePhone
                )
            }, 2000);
        });

        if (isLoading && !phonesMobile) return;

        if (phonesMobile) {
            // const markers = $('ul.phones__we-crafted li::marker');
            // console.log(markers)
            // markers.eq(0).css({color: 'red'})
        }

        const scrollLikeSlider = (event) => {
            if (!canNext) return;
            if (isUpdatePhone.isFirst) {
                if (event.deltaY < 0) {
                    $('.desktop-burger').css({display: 'block'});
                    $('.footer__up').css({display: 'flex'});
                    SmoothScroll({ stepSize: 100 });
                    $('html, body').animate({scrollTop: $('.phones-container').offset().top - 605}, 1000);
                    window.removeEventListener('wheel', scrollLikeSlider);
                    swipePhones(scrollLikeSlider, false)
                    setTimeout(() => {
                        $('body').css({overflowY: 'scroll'});
                        var ua = navigator.userAgent.toLowerCase();
                        if (ua.indexOf('safari') !== -1) {
                            if (ua.indexOf('chrome') > -1) {

                            } else {
                                $('html, body').css({'overflow-y': 'scroll'});
                            }
                        } else {
                        }
                        window.addEventListener('scroll', fixScreen);
                    }, 1000);
                    $('#iphone-model').css({position: 'absolute'});
                    return;
                }
                $('html, body').animate({scrollTop: $('.phones__second').offset().top}, 1600);
                isUpdatePhone.isFirst = false;
                isUpdatePhone.isSecond = true;
                canNext = false;
                setTimeout(() => canNext = true, 1600)
            } else if (isUpdatePhone.isSecond) {
                if (event.deltaY < 0) {
                    $('html, body').animate({scrollTop: $('.phones__first').offset().top}, 1600);
                    isUpdatePhone.isFirst = true;
                    isUpdatePhone.isSecond = false;
                } else {
                    $('html, body').animate({scrollTop: $('.phones__third').offset().top}, 1600);
                    isUpdatePhone.isSecond = false;
                    isUpdatePhone.isThird = true;
                }
                canNext = false;
                setTimeout(() => canNext = true, 1600)
            } else if (isUpdatePhone.isThird) {
                if (event.deltaY < 0) {
                    $('html, body').animate({scrollTop: $('.phones__second').offset().top}, 1600);
                    isUpdatePhone.isSecond = true;
                    isUpdatePhone.isThird = false;
                } else {
                    $('html, body').animate({scrollTop: $('.phones__fourth').offset().top}, 1600);
                    isUpdatePhone.isThird = false;
                    isUpdatePhone.isFourth = true;
                }
                canNext = false;
                setTimeout(() => canNext = true, 1600)
            } else if (isUpdatePhone.isFourth) {
                if (event.deltaY < 0) {
                    $('html, body').animate({scrollTop: $('.phones__third').offset().top}, 1600);
                    isUpdatePhone.isThird = true;
                    isUpdatePhone.isFourth = false;
                } else {
                    $('html, body').animate({scrollTop: $('.phones__fifth').offset().top}, 1600);
                    isUpdatePhone.isFourth = false;
                    isUpdatePhone.isFifth = true;
                }
                canNext = false;
                setTimeout(() => canNext = true, 1600)
            } else if (isUpdatePhone.isFifth) {
                if (event.deltaY < 0) {
                    $('html, body').animate({scrollTop: $('.phones__fourth').offset().top}, 1600);
                    isUpdatePhone.isFourth = true;
                    isUpdatePhone.isFifth = false;
                } else {
                    $('html, body').animate({scrollTop: $('.phones__sixth').offset().top}, 1600);
                    isUpdatePhone.isFifth = false;
                    isUpdatePhone.isSixth = true;
                }
                canNext = false;
                setTimeout(() => canNext = true, 1600)
            } else if (isUpdatePhone.isSixth) {
                if (event.deltaY < 0) {
                    $('html, body').animate({scrollTop: $('.phones__fifth').offset().top}, 1600);
                    isUpdatePhone.isFifth = true;
                    isUpdatePhone.isSixth = false;
                    canNext = false;
                    setTimeout(() => canNext = true, 1600)
                } else {
                    SmoothScroll({ stepSize: 100 });
                    $('html, body').animate({scrollTop: $('.our-team').offset().top}, 1600);
                    isUpdatePhone.isSixth = false;
                    $('#iphone-model').css({opacity: 0});
                    $('.desktop-burger').css({display: 'block'});
                    $('.footer__up').css({display: 'flex'});
                    setTimeout(() => {
                        $('body').css({overflowY: 'scroll'});
                        var ua = navigator.userAgent.toLowerCase();
                        if (ua.indexOf('safari') !== -1) {
                            if (ua.indexOf('chrome') > -1) {

                            } else {
                                $('html, body').css({'overflow-y': 'scroll'});
                            }
                        }
                        $('#iphone-model').css({display: 'none'});
                    }, 1600);
                    setTimeout(() => {
                        const phonesStatic = document.getElementsByClassName('phone__static');
                        for (let i = 0; i < phonesStatic.length; i++) {
                            const phone = $(phonesStatic[i]);
                            phone.attr('src', phone.data('opacityImage'));
                            phone.css({opacity: 1});
                        }
                    }, 1600);
                    window.removeEventListener('wheel', scrollLikeSlider);
                    swipePhones(scrollLikeSlider, false);
                    {
                        !phonesMobile && setTimeout(() => {
                            window.addEventListener('scroll', fixScreenDown);
                        }, 2000)
                    }
                }
            }
        }

        const phonesTop = $('.phones').offset().top - 200;

        let ourTeamTop;

        if (!phonesMobile) ourTeamTop = $('.our-team-container').offset().top - 200;

        fixScreenDown = () => {
            if (getIsDisablePhone()) {
                window.removeEventListener('scroll', fixScreenDown);
                setTimeout(() => {
                    window.addEventListener('scroll', fixScreen);
                }, 3000);
                $('#iphone-model').css({position: 'absolute', top: 99, opacity: 1, display: 'block'});
                return;
            }
            if ($('html, body').scrollTop() < ourTeamTop) {
                SmoothScroll({stepSize: 0});
                $('body').css({overflowY: 'hidden'});
                isUpdatePhone.isSixth = true;
                $('html, body').animate({scrollTop: $('.phones__sixth').offset().top}, 1000);
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {

                    } else {
                        $('html, body').css({'overflow-y': 'hidden'});
                    }
                }
                window.removeEventListener('scroll', fixScreenDown);
                $('#iphone-model').css({display: 'block'});
                $('.desktop-burger, .footer__up').css({display: 'none'});
                setTimeout(() => {
                    $('#iphone-model').css({opacity: 1});
                }, 100)
                setTimeout(() => {
                    window.addEventListener('wheel', scrollLikeSlider);
                    swipePhones(scrollLikeSlider, true);
                }, 1000);
            }
        }


        fixScreen = () => {
            if ($('html, body').scrollTop() >= phonesTop) {
                cancelAnimationFrame(getPackRequestId())
                SmoothScroll({stepSize: 0});
                $('body').css({overflowY: 'hidden'});
                $('html, body').animate({scrollTop: phonesTop + 200}, 500);
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {

                    } else {
                        $('html, body').css({'overflow-y': 'hidden'});
                    }
                }
                isUpdatePhone.isFirst = true;
                window.removeEventListener('scroll', fixScreen);
                $('.desktop-burger, .footer__up').css({display: 'none'});
                setTimeout(() => {
                    $('#iphone-model').css({position: 'fixed', top: $('#iphone-model').position().top});
                    window.addEventListener('wheel', scrollLikeSlider);
                    swipePhones(scrollLikeSlider, true);
                }, 1000);
            }
        }

        !phonesMobile && window.addEventListener('scroll', fixScreen);

    }, [isLoading])

    if (isLoading && !phonesMobile) return <div/>


    return (
        <section className='phones-container'>
            {phonesMobile ? <svg className='phones__first-svg' width="67" height="3" viewBox="0 0 67 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 2C15.8649 1 52.1351 1 67 1" stroke="#146957"/>
            </svg> : ''
            }
            {phonesMobile ? <svg className='phones__second-svg' width="93" height="3" viewBox="0 0 93 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 2C21.7207 1 72.2793 1 93 1" stroke="#146957"/>
                </svg>
                : ''
            }
            {phonesMobile ? <svg className='phones__third-svg' width="114" height="3" viewBox="0 0 114 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 2C26.4505 1 88.5495 1 114 1" stroke="#146957"/>
                </svg>
                : ''
            }
            {!phonesMobile ? <img className='phones-container__night' src={mobileApp}/> : <Fragment/>}
            <article className='phones phones__first'>
                {phonesMobile && <img style={apiData.isEnglish ? {width: '62vw'} : {}} src={!apiData.isEnglish ? phone0 : phone1eng} className='phones__zero-phone'/>}
                <div className='phones__night-image'>
                    {!phonesMobile ? <Fragment/> : <img data-image={nightMobile}/>}
                    <div className='phones__text-about-app'>
                        <h5 className='phones__not-about-food' dangerouslySetInnerHTML={{__html: apiData.phones_night_not_about_food}}>
                        </h5>
                        {
                            !phonesMobile ? <p className='phones__we-crafted' dangerouslySetInnerHTML={{__html: apiData.phones_night_we_crafted}}>
                            </p> : <p className='phones__we-crafted' dangerouslySetInnerHTML={{__html: apiData.phones_night_we_crafted}}>
                                {/*<svg width="242" height="5" viewBox="0 0 242 5" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                {/*    <path d="M1 4.00019C49.8664 2.84598 168.5 -0.0897449 241 1.5" stroke="#146957"/>*/}
                                {/*</svg>*/}
                            </p>
                        }
                        <ul className='phones__we-crafted'>
                            {
                                !phonesMobile ? <Fragment>
                                    <li dangerouslySetInnerHTML={{__html: apiData.phones_night_first_li}}></li>
                                    <li dangerouslySetInnerHTML={{__html: apiData.phones_night_second_li}}></li>
                                    <li dangerouslySetInnerHTML={{__html: apiData.phones_night_third_li}}></li>
                                    <li dangerouslySetInnerHTML={{__html: apiData.phones_night_fourth_li}}></li>
                                </Fragment> : <Fragment>
                                    <li style={{color: '#146957', marginLeft: 15}}><span dangerouslySetInnerHTML={{__html: apiData.phones_night_first_li}}></span></li>
                                    <li style={{color: '##117592', marginLeft: 15}}><span dangerouslySetInnerHTML={{__html: apiData.phones_night_second_li}}></span></li>
                                    <li style={{color: '#F3B03E', marginLeft: 15}}><span dangerouslySetInnerHTML={{__html: apiData.phones_night_third_li}}></span></li>
                                    <li style={{color: '#CD3146', marginLeft: 15}}><span  dangerouslySetInnerHTML={{__html: apiData.phones_night_fourth_li}}></span></li>
                                </Fragment>
                            }
                        </ul>
                    </div>
                </div>
                <div id='iphone-model'/>
            </article>
            <article className='phones phones__second'>
                {phonesMobile ? <svg className={'phones__number-one'} width="66" height="136" viewBox="0 0 66 136" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.7" d="M26.68 38.53V136H65.63V0.149994H32L0.84 22V54.49L26.68 38.53Z" fill="white"/>
                </svg> : <Fragment/>
                }
                {!phonesMobile
                    ? <div className='phones__ellipse'>
                        {/*<img className='phone__static' data-opacity-image={phoneDesktop1}/>*/}
                    </div>
                    : <div className='phones__ellipse'>
                        <img data-image={!apiData.isEnglish ? phone1 : phone2eng}/>
                    </div>}
                <div className={!phonesMobile ? 'phones__text' : 'phones-mobile__text'}>
                    <h6 dangerouslySetInnerHTML={{__html: apiData.phones_first_title}}></h6>
                    <p dangerouslySetInnerHTML={{__html: apiData.phones_first_little_title}}></p>
                    <span dangerouslySetInnerHTML={{__html: apiData.phones_first_description}}>
                    </span>
                </div>
                {!phonesMobile && <img data-image={phoneFirst}/>}
            </article>
            <article className='phones phones__third'>
                {phonesMobile ? <svg className={'phones__number-two'} width="86" height="138" viewBox="0 0 86 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.7" d="M0.28 132.49V138H109.53V108.93H63.93L82.93 90.5C90.53 83.09 96.99 76.06 101.55 68.27C105.92 60.67 108.58 52.5 108.58 43.19C108.58 30.84 104.02 20.01 95.66 12.41C87.11 4.80999 74.38 0.249995 57.66 0.249995C24.79 0.249995 4.27 17.54 2.94 51.55H38.28C39.99 35.4 46.07 29.51 55.76 29.51C65.45 29.51 70.2 35.59 70.2 44.71C70.2 56.11 61.46 67.7 47.78 82.14L0.28 132.49Z" fill="white"/>
                    </svg>
                    : <Fragment/>
                }
                {!phonesMobile
                    ? <div className='phones__ellipse'>
                        {/*<img className='phone__static' data-opacity-image={phoneDesktop2}/>*/}
                    </div>
                    : <div className='phones__ellipse'>
                        <img data-image={!apiData.isEnglish ? phone2 : phone3eng}/>
                    </div>}
                <div className={!phonesMobile ? 'phones__text' : 'phones-mobile__text'}>
                    <h6 dangerouslySetInnerHTML={{__html: apiData.phones_second_title}}></h6>
                    <p dangerouslySetInnerHTML={{__html: apiData.phones_second_little_title}}></p>
                    <span dangerouslySetInnerHTML={{__html: apiData.phones_second_description}}>
                    </span>
                </div>
                {!phonesMobile && <img data-image={phoneSecond}/>}
            </article>
            <article className='phones phones__fourth'>
                {phonesMobile ? <svg className='phones__number-three' width="82" height="141" viewBox="0 0 82 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.5" d="M56.7 140.28C92.61 140.28 111.8 123.37 111.8 97.53C111.8 80.81 101.16 69.98 85.39 66.75C99.83 62.38 108.38 52.69 108.38 37.49C108.38 26.09 103.44 16.97 94.89 10.32C85.96 4.04999 73.23 0.249995 57.27 0.249995C40.36 0.249995 27.44 4.99999 18.51 12.79C9.58 20.96 4.64 31.98 3.88 45.47H38.27C39.79 34.26 45.68 28.18 56.32 28.18C66.77 28.18 71.9 33.5 71.9 41.67C71.9 51.36 66.01 56.68 53.85 56.68H43.78V80.81H54.61C66.58 80.81 72.47 86.13 72.47 96.77C72.47 106.46 66.77 112.16 54.61 112.16C40.93 112.16 35.99 104.75 34.66 93.35H0.0800003C1.03 122.8 18.89 140.28 56.7 140.28Z" fill="white"/>
                    </svg>
                    : <Fragment/>}
                {!phonesMobile
                    ? <div className='phones__ellipse'>
                        {/*<img className='phone__static' data-opacity-image={phoneDesktop1}/>*/}
                    </div>
                    : <div className='phones__ellipse'>
                        <img data-image={!apiData.isEnglish ? phone3 : phone4eng}/>
                    </div>}
                <div className={!phonesMobile ? 'phones__text' : 'phones-mobile__text'}>
                    <h6 dangerouslySetInnerHTML={{__html: apiData.phones_third_title}}></h6>
                    <p dangerouslySetInnerHTML={{__html: apiData.phones_third_little_title}}></p>
                    <span dangerouslySetInnerHTML={{__html: apiData.phones_third_description}}>
                    </span>
                </div>
                {!phonesMobile && <img data-image={phoneThird}/>}
            </article>
            <article className='phones phones__fifth'>
                {phonesMobile ? <svg className='phones__number-four' width="84" height="136" viewBox="0 0 84 136" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.7" d="M66.3 109.97V136H103.73V109.97H121.21V83.18H103.73V0.149994H56.8L0.18 95.34V109.97H66.3ZM67.25 83.18H36.85L67.25 29.41V83.18Z" fill="white"/>
                    </svg>

                    : <Fragment/>}
                {!phonesMobile
                    ? <div className='phones__ellipse'>
                        {/*<img className='phone__static' data-opacity-image={phoneDesktop2}/>*/}
                    </div>
                    : <div className='phones__ellipse'>
                        <img data-image={!apiData.isEnglish ? phone4 : phone5eng}/>
                    </div>}
                <div className={!phonesMobile ? 'phones__text' : 'phones-mobile__text'}>
                    <h6 dangerouslySetInnerHTML={{__html: apiData.phones_fourth_title}}></h6>
                    <p dangerouslySetInnerHTML={{__html: apiData.phones_fourth_little_title}}></p>
                    <span dangerouslySetInnerHTML={{__html: apiData.phones_fourth_description}}>
                    </span>
                </div>
                {!phonesMobile && <img data-image={phoneFourth}/>}
            </article>
            <article className='phones phones__sixth'>
                {phonesMobile ? <svg className='phones__number-five' width="83" height="139" viewBox="0 0 83 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.7" d="M54.09 138.28C71.38 138.28 84.87 133.53 94.56 125.17C103.87 116.81 109.19 104.84 109.19 90.4C109.19 75.77 105.01 63.99 97.98 56.2C90.57 48.41 80.31 44.23 67.96 44.23C51.05 44.23 41.36 51.26 36.23 58.67L36.99 28.65H100.45V0.149994H8.87L6.02 80.14H29.2C33.76 74.82 40.41 70.45 51.62 70.45C63.59 70.45 70.05 77.67 70.05 89.83C70.05 100.66 65.11 109.02 53.71 109.02C47.44 109.02 43.26 107.5 40.79 104.65C37.94 101.8 36.8 98 36.04 93.25H0.7C1.27 105.98 5.45 117.19 14.38 125.36C22.93 133.53 36.04 138.28 54.09 138.28Z" fill="white"/>
                    </svg>
                    : <Fragment/>}
                {!phonesMobile
                    ? <div className='phones__ellipse'>
                        {/*<img className='phone__static' data-opacity-image={phoneDesktop1}/>*/}
                    </div>
                    : <div className='phones__ellipse'>
                        <img data-image={!apiData.isEnglish ? phone5 : phone6eng}/>
                    </div>}
                <div className={!phonesMobile ? 'phones__text' : 'phones-mobile__text'}>
                    <h6 dangerouslySetInnerHTML={{__html: apiData.phones_fifth_title}}></h6>
                    <p dangerouslySetInnerHTML={{__html: apiData.phones_fifth_little_title}}></p>
                    <span dangerouslySetInnerHTML={{__html: apiData.phones_fifth_description}}>
                    </span>
                </div>
                {!phonesMobile && <img data-image={phoneFirst}/>}
            </article>
            {
                phonesMobile && !apiData.isEnglish ? <img className='phones__opacity' src={opacityPhone}/> : <Fragment/>
            }
        </section>
    )
}