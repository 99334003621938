import React, {useEffect} from 'react';
import MaskedInput from 'react-text-mask';
import {useMediaQuery} from "react-responsive";

export default function PhoneNumber() {
    const subscribeFormMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });
    return (
        <div className='subscribe-form__line subscribe-form__phone-number-block' style={{height: 91, opacity: 1}}>
            {
                !subscribeFormMobile
                    ? <h6 className='subscribe-form__title' style={{paddingBottom: 30}}>Телефон</h6>
                    : <h6 className='subscribe-form__title' style={{top: 175}}>Телефон</h6>
            }
            <MaskedInput className='subscribe-form__phone-number' placeholder='Ваш телефон'
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]} guide={true}
            />
        </div>
    )
}