import React, { useState, Fragment, useEffect } from 'react';
// import { useBeforeunload } from 'react-beforeunload';
import $ from 'jquery';
import {useMediaQuery} from 'react-responsive';
// import ReactDOMServer from 'react-dom/server';
import './index.css'

import {LoaderPromise} from "./components/pack/loaderPromise";

import FirstScreen from "./components/first-screen";
import HowItWorks from "./components/how-it-works";
import FoodMenu from "./components/food-menu";
import SubscribeForm from "./components/subscribe-form";
import HealthInfo from "./components/health-info";
import Reviews from "./components/reviews";
import Phones from "./components/phones";
import OurTeam from "./components/our-team";
import IsNotHype from "./components/is-not-hype";
import SpiroIsQuality from "./components/spiro-is-quality";
import QuestionsAndAnswers from "./components/question-and-answer";
import AddEmail from "./components/add-email";
import Hippocrates from "./components/hippocrates";

import HealthInfoMobile from "./components/health-info/healthInfoMobile";

import Footer from "./components/Footer";
import FoodMenuMobile from "./components/food-menu/foodMenuMobile";
import FoodAsMedicine from "./components/food-as-a-medicine";
import OurTeamMobile from "./components/our-team/ourTeamMobile";
import SiteNavigationAfterScroll from "./siteNavigationAfterScroll";
import Superset from "./components/superset";
import Metrika from "./components/metrika";

import SpiroLangingVideo from '../public/firstScreen/spiro-landing.mp4'

var ua = navigator.userAgent.toLowerCase();

let image, obj

let screenHeight;
let appMobile;

let isNotHypeBlock
const blurIsNotHype = () => {
    if (!isNotHypeBlock) {
        isNotHypeBlock = $('.is-not-hype p');
    }
    const filterVal = 'blur(' + 0 + 'px)';
    if ($('html, body').scrollTop() > isNotHypeBlock.offset().top - screenHeight + 150) {
        isNotHypeBlock.css({
            'filter': filterVal,
            'webkitFilter': filterVal,
            'mozFilter': filterVal,
            'oFilter': filterVal,
            'msFilter': filterVal,
            'transition': 'all 1s ease-out',
            '-webkit-transition': 'filter 1s ease-out, webkitFilter 1s ease-out, mozFilter 1s ease-out, oFilter 1s ease-out, msFilter 1s ease-out',
            '-moz-transition': 'filter 1s ease-out, webkitFilter 1s ease-out, mozFilter 1s ease-out, oFilter 1s ease-out, msFilter 1s ease-out',
            '-o-transition': 'filter 1s ease-out, webkitFilter 1s ease-out, mozFilter 1s ease-out, oFilter 1s ease-out, msFilter 1s ease-out'
        });


        window.removeEventListener('scroll', blurIsNotHype);
    }
}

let healthInfoBlock;
const parallaxHealthInfo = () => {
    setTimeout(() => {
        const reviewers = document.getElementsByClassName('reviewer');
        for (let i = 0; i < reviewers.length; i++) {
            const image = $(reviewers[i]).find('.reviewer__image');
            image.attr('src', image.data('image'))
        }
        $('.phones-container__night').attr('src', $('.phones-container__night').data('image'));
        $('.phones__second > img').attr('src', $('.phones__second > img').data('image'));
        $('.phones__third > img').attr('src', $('.phones__third > img').data('image'));
        $('.phones__fourth > img').attr('src', $('.phones__fourth > img').data('image'));
        $('.phones__fifth > img').attr('src', $('.phones__fifth > img').data('image'));
        $('.phones__sixth > img').attr('src', $('.phones__sixth > img').data('image'));
        const ourTeamMembers = document.getElementsByClassName('our-team__member');
        for (let i = 0; i < ourTeamMembers.length; i++) {
            const image = $(ourTeamMembers[i]).find('img:first-child');
            image.attr('src', image.data('image'));
        }
    })

    window.removeEventListener('scroll', parallaxHealthInfo);
    window.addEventListener('scroll', blurIsNotHype);
}

const loadImagesMobile = () => {
    if (!healthInfoBlock) {
        healthInfoBlock = $('.health-info-mobile');
    }
    const currentScrollTop = $('html, body').scrollTop();
    if (currentScrollTop > healthInfoBlock.offset().top - screenHeight) {
        setTimeout(() => {
            $('.phones__night-image img').attr('src', $('.phones__night-image img').data('image'));
            $('.phones__second img').attr('src', $('.phones__second img').data('image'));
            $('.phones__third img').attr('src', $('.phones__third img').data('image'));
            $('.phones__fourth img').attr('src', $('.phones__fourth img').data('image'));
            $('.phones__fifth img').attr('src', $('.phones__fifth img').data('image'));
            $('.phones__sixth img').attr('src', $('.phones__sixth img').data('image'));
            const ourTeamMembers = document.getElementsByClassName('our-team-mobile__member');
            for (let i = 0; i < ourTeamMembers.length; i++) {
                const image = $(ourTeamMembers[i]).find('img');
                image.attr('src', image.data('image'));
            }
        })
    }
}

let subscribeFormBlocks;
let subscribeFormDelay = 500;
const subscribeFormAnimation = () => {
    if (!subscribeFormBlocks) {
        subscribeFormBlocks = $('.subscribe-form__line');
        if (appMobile) {
            const reviewer = document.getElementsByClassName('reviewer');
            for (let i = 0; i < reviewer.length; i++) {
                const image = $(reviewer[i]).find('.reviewer__image');
                image.attr('src', image.data('image'));
            }
            window.removeEventListener('scroll', subscribeFormAnimation);
            window.addEventListener('scroll', loadImagesMobile);
        }
    }
    if (!appMobile && $('html, body').scrollTop() > subscribeFormBlocks.offset().top - screenHeight + 350) {
        subscribeFormBlocks.each(function() {
            $(this).animate({opacity: 1}, subscribeFormDelay);
            subscribeFormDelay += 500;
        })
        window.removeEventListener('scroll', subscribeFormAnimation);
        window.addEventListener('scroll', parallaxHealthInfo);
    }
}

let foodMenuDishes;
function foodMenuAnimation() {
    if (($('html, body').scrollTop() > $('.food-menu__dish').offset().top - screenHeight + 350)) {
        window.removeEventListener('scroll', foodMenuAnimation);
        window.addEventListener('scroll', subscribeFormAnimation);
    }
}

let ourPhilosophy;

let appBlock;

const ourPhilosophyAnimation = (event) => {
    if (!ourPhilosophy) {
        ourPhilosophy = $('.our-philosophy');
        screenHeight = window.innerHeight;
    }
    const screenHeightConst = !appMobile ? 150 : 150;
    if ($('html, body').scrollTop() > ourPhilosophy.offset().top - screenHeight + screenHeightConst) {
        ourPhilosophy.css({opacity: 1})
        !appMobile
            ? ourPhilosophy.animate({width: 872}, 750)
            : ourPhilosophy.animate({width: '100vw'}, 500);
        setTimeout(() => {
            $('.our-philosophy__spiro-photo').animate({opacity: 1}, !appMobile ? 750 : 500);
            $('.our-philosophy__background *').animate({opacity: 1}, !appMobile ? 750 : 500);
        }, 750)
        window.removeEventListener('scroll', ourPhilosophyAnimation);
        window.addEventListener('scroll', foodMenuAnimation)
    }
}

let disablePackAnimation = false;
const getDisablePackAnimation = () => disablePackAnimation;
const setDisablePackAnimation = (value) => disablePackAnimation = value;

let packRequestId;
const getPackRequestId = () => packRequestId;
const setPackRequestId = (value) => packRequestId = value;

let apiData;

function App() {
    const [loaderValues, setLoaderValues] = useState({image: '', obj: '', isLoading: true});
    appMobile = useMediaQuery({
        query: '(max-width: 992px)'
    })
    useEffect(() => {
        if (loaderValues.isLoading) return;

        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {

            } else {
                $('html, body').css({'overflow-x': 'hidden', 'overflow-y': 'hidden', position: 'relative'});
            }
        }

        window.addEventListener('scroll', ourPhilosophyAnimation);

    }, [loaderValues.isLoading])

    const SubscribeFormMemo = React.memo((apiData) => <SubscribeForm apiData={apiData}/>);

    const isFtp = false;

    if (loaderValues.isLoading) {
        LoaderPromise().then(values => {
            [image, obj] = values;
            if (!isFtp) {
                fetch('/api/main-page')
                    .then(res => res.json())
                    .then(data => {
                        apiData = data[0];
                        $('.loading').animate({opacity: 0}, 500);
                        setTimeout(() => setLoaderValues({image: image, obj: obj, isLoading: false}), 500)
                    })
            } else {
                $('.loading').animate({opacity: 0}, 500);
                setTimeout(() => setLoaderValues({image: image, obj: obj, isLoading: false}), 500)
            }
        })
        return (
            <div className='loading'>
                <div className='loading__circle'/>
            </div>
        );
    }

    let isDisablePhone;
    const getIsDisablePhone = () => isDisablePhone;
    const setIsDisablePhone = (value) => isDisablePhone = value;

    const isAdmin = false;

    return (
        <Fragment>
            {
                isAdmin ? <div className='admin-save'>Сохранить</div> : ''
            }
            <SiteNavigationAfterScroll isEnglish={apiData.isEnglish} setIsDisablePhone={setIsDisablePhone}/>
            <FirstScreen apiData={apiData} setPackRequestId={setPackRequestId} isAdmin={isAdmin} setDisablePackAnimation={setDisablePackAnimation} disablePackAnimation={getDisablePackAnimation} packValues={[image, obj]} firstScreenMobile={appMobile}/>
            <FoodAsMedicine apiData={apiData} isAdmin={isAdmin}/>
            <HowItWorks apiData={apiData}/>
            {
                !appMobile ? <FoodMenu isEnglish={apiData.isEnglish}/> : <FoodMenuMobile isEnglish={apiData.isEnglish}/>
            }
            <Superset apiData={apiData}/>
            <SubscribeFormMemo apiData={apiData}/>
            {
                !appMobile ? <HealthInfo apiData={apiData}/> : <HealthInfoMobile apiData={apiData}/>
            }
            {/*<Reviews apiData={apiData}/>*/}
            <Phones apiData={apiData} getPackRequestId={getPackRequestId} getIsDisablePhone={getIsDisablePhone}/>
            {
                !appMobile ? <OurTeam apiData={apiData}/> : <OurTeamMobile apiData={apiData}/>
            }
            <IsNotHype apiData={apiData}/>
            <SpiroIsQuality apiData={apiData} video={SpiroLangingVideo}/>
            {/*<QuestionsAndAnswers apiData={apiData}/>*/}
            <SubscribeFormMemo apiData={apiData}/>
            <AddEmail isEnglish={apiData.isEnglish}/>
            <Hippocrates apiData={apiData} setIsDisablePhone={setIsDisablePhone}/>
            <Footer isEnglish={apiData.isEnglish} setIsDisablePhone={setIsDisablePhone}/>
            {/*<Metrika/>*/}
        </Fragment>
    );
}

export default App;
