import React, {Fragment} from 'react';
import {Parallax} from 'react-scroll-parallax';

import image from '../../../public/healthInfo/health-info-second.png';

let isSafari;

var ua = navigator.userAgent.toLowerCase();

export default function Second(props) {
    const {apiData} = props;

    if (ua.indexOf('safari') !== -1) {
        if (ua.indexOf('chrome') > -1) {

        } else {
            isSafari = true;
        }
    }

    return (
        <div className='health-info__second health-info__block'>
            {
                isSafari
                    ? <Parallax y={[0, 20]}>
                        <img className='health-info__image-second health-info__image' data-image={image}/>
                    </Parallax>
                    : <img className='health-info__image-second health-info__image' data-image={image}/>
            }
            {
                !isSafari
                    ? <Parallax y={[10, -10]}>
                        <div className='health-info__circle'>
                            <Parallax y={[20, -20]}>
                                <h6 className='health-info__title health-info__title-second' dangerouslySetInnerHTML={{__html: apiData.health_info_second_main}}></h6>
                                <p className='health-info__description health-info__description-second' dangerouslySetInnerHTML={{__html: apiData.health_info_second_under_main}}>
                                </p>
                            </Parallax>
                        </div>
                    </Parallax>
                    : <Fragment>
                        <div className='health-info__circle'>
                            <Fragment>
                                <h6 style={{marginTop: 0}}  className='health-info__title health-info__title-second' dangerouslySetInnerHTML={{__html: apiData.health_info_second_main}}></h6>
                                <p className='health-info__description health-info__description-second' dangerouslySetInnerHTML={{__html: apiData.health_info_second_under_main}}>
                                </p>
                            </Fragment>
                        </div>
                    </Fragment>
            }
        </div>
    )
}