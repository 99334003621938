import React from "react";
import $ from "jquery";

export default function Superset(props) {
    const changeActivity = () => {
        $('html, body').animate({scrollTop: $('.superset-container').offset().top}, 1000);
    }

    return (
        <div className={'food-menu__superset'} onClick={changeActivity}>
            <span>SUPERSET</span>
        </div>
    )
}