import React, {Fragment, useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';

import dishWithDessert from '../../../public/firstScreen/dish-with-dessert.png';
import energy from '../../../public/foodAsMedicine/energy.svg';
import digestion from '../../../public/foodAsMedicine/better-digestion.svg';
import clearSkin from '../../../public/foodAsMedicine/clear-skin.svg';
import stamina from '../../../public/foodAsMedicine/stamina.svg';
import normalWeight from '../../../public/foodAsMedicine/normal-weight.svg';
import strengthenedImmunity from '../../../public/foodAsMedicine/strengthened-immunity.svg';
import concentration from '../../../public/foodAsMedicine/concentration.svg';
import spiroPhoto from '../../../public/foodAsMedicine/spiro-photo.png';
import spiroPhotoDesktop from '../../../public/foodAsMedicine/spiro-photo-desktop.png';
import spiroSymbol from '../../../public/foodAsMedicine/symbol-spiro.svg';

const WhiteEllipseWithText = (props) => {
    const {apiData} = props;
    return (
        <Fragment>
            <p className={`food-as-medicine__main-text ${apiData.isEnglish ? 'food-as-medicine__main-text-eng' : ''}`} dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_main_text}}>
            </p>
            <p className={
                `food-as-medicine__text-under-main-text ${apiData.isEnglish ? 'food-as-medicine__text-under-main-text_english' : ''}`}
               dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_under_main_text}}>
            </p>
            <p className='food-as-medicine__get-energy food-as-medicine_little-text'>
                <img src={energy}/>
                <span dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_energy}}></span>
            </p>
            <p className='food-as-medicine__better-digestion food-as-medicine_little-text'>
                <img src={digestion}/>
                <span dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_digestion}}></span>
            </p>
            <p className='food-as-medicine__clear-skin food-as-medicine_little-text'>
                <img src={clearSkin}/>
                <span dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_skin}}></span>
            </p>
            <p className='food-as-medicine_little-text food-as-medicine__stamina'>
                <img src={stamina}/>
                <span dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_stamina}}></span>
            </p>
            <p className='food-as-medicine_little-text food-as-medicine__normal-weight'>
                <img src={normalWeight}/>
                <span dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_weight}}></span>
            </p>
            <p className='food-as-medicine__strengthened-immunity food-as-medicine_little-text'>
                <img src={strengthenedImmunity}/>
                <span dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_immunity}}></span>
            </p>
            <img className='food-as-medicine__dish-with-dessert' src={dishWithDessert}/>
            <p className='food-as-medicine__concentration food-as-medicine_little-text'>
                <img src={concentration}/>
                <span dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_concentration}}></span>
            </p>
        </Fragment>
    )
}

const WhiteEllipseWithTextMobile = (props) => {
    const {apiData} = props;
    return (
        <Fragment>
            <p className={`food-as-medicine__main-text ${apiData.isEnglish ? 'food-as-medicine__main-text-eng' : ''}`} dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_main_text}}>
            </p>
            <p className={
                `food-as-medicine__text-under-main-text ${apiData.isEnglish ? 'food-as-medicine__text-under-main-text_english' : ''}`}
               dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_under_main_text}}>
            </p>
            <img className='dish-with-dessert-static' src={dishWithDessert}/>
            <div className='food-as-medicine__mobile-text'>
                <p className='food-as-medicine_little-text'>
                    <img src={energy}/>
                    <span dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_energy}}></span>
                </p>
                <p className=' food-as-medicine_little-text'>
                    <img src={digestion}/>
                    <span dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_digestion}}></span>
                </p>
                <p className=' food-as-medicine_little-text'>
                    <img src={clearSkin}/>
                    <span dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_skin}}></span>
                </p>
                <p className='food-as-medicine_little-text' >
                    <img src={stamina}/>
                    <span dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_stamina}}></span>
                </p>
                <p className='food-as-medicine_little-text'>
                    <img src={normalWeight}/>
                    <span dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_weight}}></span>
                </p>
                <p className=' food-as-medicine_little-text'>
                    <img src={strengthenedImmunity}/>
                    <span dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_immunity}}></span>
                </p>
                <p className=' food-as-medicine_little-text'>
                    <img src={concentration}/>
                    <span dangerouslySetInnerHTML={{__html: apiData.food_as_medicine_concentration}}></span>
                </p>
            </div>
        </Fragment>
    )
}

const OurPhilosophyText = (props) => {
    const {apiData} = props;
    return (
        <Fragment>
            <p dangerouslySetInnerHTML={{__html: apiData.our_philosolhy_main_text}}>
            </p>
            <span dangerouslySetInnerHTML={{__html: apiData.our_philosophy_who_said}}></span>
        </Fragment>
    )
}

export default function FoodAsMedicine(props) {
    const {apiData} = props;
    const foodAsMedicineMobile = useMediaQuery({
        query: '(max-width: 992px)'
    })

    return (
        <section className='food-as-medicine'>
            <div className='blue-ellipse'>
                <div className='white-ellipse'>
                    {
                        !foodAsMedicineMobile
                            ? <Fragment>
                                <WhiteEllipseWithText apiData={apiData}/>
                            </Fragment>
                            : <WhiteEllipseWithTextMobile apiData={apiData}/>
                    }
                </div>
                <div className='blue-block'/>
            </div>
            <article className='our-philosophy'>
                <section className='our-philosophy__background'>
                    <img src={spiroSymbol}/>
                    <OurPhilosophyText apiData={apiData}/>
                </section>
                {
                    !foodAsMedicineMobile ? <img className='our-philosophy__spiro-photo' src={spiroPhotoDesktop}/> : <img className='our-philosophy__spiro-photo' src={spiroPhoto}/>
                }
            </article>
        </section>
    )
}