import React, {useEffect} from 'react';
import Slider from 'react-slick';
import $ from 'jquery'
// import aksukMobile from "../../../public/ourTeam/aksukMobile.jpg";
import spiroMobile from '../../../public/ourTeam/spiro-mobile-our-team.png';

const settings = {
    className: 'our-team-mobile-carousel',
    dots: true,
    slidesToShow: window.location.host === 'spirocooking.ru' ? 4 : 3,
    slidesToScroll: 1,
    speed: 1500,
    infinite: true,
    responsive: [
        {
            breakpoint: 555,
            settings: {
                slidesToShow: 2
            }

        }
    ],
    customPaging: (i) => {
    return (
        <a>
            <div className='dot-our-team'/>
        </a>
    )
    },
    dotsClass: 'slick-dots slick-dots-our-team'
}

export default function OurTeamMobile(props) {
    const {apiData} = props;

    useEffect(() => {
        if (!apiData.isEnglish) return;
        $('.dot-our-team').each(function(index) {
            if (index === 0) {
                $(this).css({width: 65, marginLeft: -240})
            }
            if (index === 1) {
                $(this).css({width: 65, marginLeft: -200})
            }
            if (index === 2) {
                $(this).css({width: 65, marginLeft: -160})
            }
        })
        $(window).width() >= 555 && $('.our-team-mobile-carousel').attr('style', 'width: unset!important')
    }, [])

    return (
        <section className='our-team-mobile'>
            <h5 className='our-team__text our-team-mobile__text'>
                {!apiData.isEnglish ? 'Наша команда' : 'Our team'}
            </h5>
            <div className='our-team__background'/>
            <Slider {...settings}>
                {
                    apiData.our_team.map(el => {
                        const member = el.split('///');
                        return (
                            <div className='our-team-mobile__member'>
                                <img data-image={`/images/${member[3]}`}/>
                                <div className='our-team-mobile__about-member'>
                                    <h6 dangerouslySetInnerHTML={{__html: member[0]}}></h6>
                                    {/*<span dangerouslySetInnerHTML={{__html: member[1]}}></span>*/}
                                    <p dangerouslySetInnerHTML={{__html: member[2]}}></p>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
        </section>
    )
}
