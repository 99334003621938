import React, {useRef, useState, useEffect, useCallback} from 'react';
import $ from 'jquery'

import CurrentWeek from "./weeks/currentWeek";
import NextWeek from "./weeks/nextWeek";
import Monday from "./days/monday";
import Tuesday from "./days/tuesday";
import Wednesday from "./days/wednesday";
import Thursday from "./days/thursday";
import Friday from "./days/friday";
import Breakfast from "./times/breakfast";
import Dinner from "./times/dinner";
import Superset from "./times/superset";
import Lunch from "./times/lunch";
import FoodMenuDishes from "./dishes";
import {useDishes} from "./hooks";

const isHere = (object) => object.length !== 0;

const isOnlyOne = (value) => value.length === 1;
const isOnlyTwo = (value) => value.length === 2;

const changeDishes = (firstDish, secondDish, thirdDish, secondDishImg) => {
    isHere(secondDish) && firstDish.animate({left: -500}, 1000);
    isHere(secondDish) && secondDish.animate({width: 418, height: 501, left: 0, zIndex: -100}, 1000);
    isHere(secondDish) && secondDishImg.animate({width: 418, height: 418}, 1000)
    isHere(thirdDish) && thirdDish.length && thirdDish.animate({left: 125}, 1000);
}

const changeDishesTimeout = (firstDish, firstDishImg, thirdDish) => {
    firstDish.css({left: 525, width: 370, height: 442, zIndex: -2000});
    firstDishImg.css({width: 370, height: 370});
    thirdDish.css({zIndex: -1000});
}

const changeDishedRight = (firstDish, firstDishImg, secondDish, thirdDish, secondDishImg) => {
    firstDish.css({left: -500, zIndex: -100, width: 418, height: 501});
    firstDishImg.css({width: 418, height: 418});
    secondDish.css({zIndex: -1000});
    thirdDish.css({zIndex: -2000});
    firstDish.animate({left: 0}, 1000);
    secondDish.animate({left: 125, width: 370, height: 442}, 1000);
    secondDishImg.animate({width: 370, height: 370}, 1000);
    thirdDish.animate({left: 525}, 1000);
}

let dishesExternal = {}

export default function FoodMenu(props) {
    const [dishes, _, setWeek, setDayOfWeek] = useDishes()
    const [currentMealType, setCurrentMealType] = useState('breakfast')
    const [currentDishIdx, setCurrentDishIdx] = useState(0)
    const [carouselStarted, setCarouselStarted] = useState(false);
    const {isEnglish} = props;

    useEffect(() => {
        let whoIsFirst = 'current';
        let whoIsLast = 'copy';
        if (dishes) {
            if (isOnlyOne(dishes[currentMealType])) {
                $('.food-menu__button-left, .food-menu__button-right').css({opacity: 0, cursor: 'unset'});
            } else {
                $('.food-menu__button-left').css({opacity: .5, cursor: 'unset'});
                $('.food-menu__button-right').css({opacity: 1, cursor: 'pointer'});
            }
        }
        const currentDish = $('.food-menu__dish-current')
        const nextDish = $('.food-menu__dish-next');
        const copyDish = $('.food-menu__dish-copy');
        const currentDishImg = $('.food-menu__dish-current img');
        const nextDishImg = $('.food-menu__dish-next img');
        const copyDishImg = $('.food-menu__dish-copy img');
        let isBlocked;
        $('.food-menu__button-left').click(() => {
            if (isBlocked) return;
            isBlocked = true;
            if (whoIsLast === 'copy') {
                if (isOnlyOne(dishes[currentMealType]) || isOnlyTwo(dishes[currentMealType])) {
                    isBlocked = false;
                    return;
                }
                changeDishedRight(copyDish, copyDishImg, currentDish, nextDish, currentDishImg);
                currentDish.css({filter: 'blur(2.5px)'});
                setCurrentDishIdx(2)

                setTimeout(() => {
                    nextDish.css({filter: 'blur(0)'});
                    isBlocked = false;
                }, 1100)

                whoIsLast = 'next';
                whoIsFirst = 'copy';
            } else if(whoIsLast === 'next') {
                // if (isOnlyOne(dishes[currentMealType])) return;
                changeDishedRight(nextDish, nextDishImg, copyDish, currentDish, copyDishImg);
                copyDish.css({filter: 'blur(2.5px)'});
                setCurrentDishIdx(1)

                setTimeout(() => {
                    currentDish.css({filter: 'blur(0)'});
                    isBlocked = false;
                }, 1100)

                whoIsLast = 'current';
                whoIsFirst = 'next';
            } else if(whoIsLast === 'current') {
                changeDishedRight(currentDish, currentDishImg, nextDish, copyDish, nextDishImg);
                if (isOnlyTwo(dishes[currentMealType])) {
                    $('.food-menu__button-right').css({opacity: 1, cursor: 'pointer'});
                    $('.food-menu__button-left').css({opacity: .5, cursor: 'unset'});
                }
                nextDish.css({filter: 'blur(2.5px)'});
                setCurrentDishIdx(0)

                setTimeout(() => {
                    copyDish.css({filter: 'blur(0)'});
                    isBlocked = false;
                }, 1100)

                whoIsLast = 'copy';
                whoIsFirst = 'current';
            }
        })
        $('.food-menu__button-right').click(() => {
            if (isBlocked) return;
            isBlocked = true;
            if (whoIsFirst === 'current') {
                if (isOnlyOne(dishes[currentMealType])) {
                    isBlocked = false;
                    return;
                }
                if (isOnlyTwo(dishes[currentMealType])) {
                    $('.food-menu__button-right').css({opacity: .5, cursor: 'unset'});
                    $('.food-menu__button-left').css({opacity: 1, cursor: 'pointer'});
                } else {
                    $('.food-menu__button-left').css({opacity: 1, cursor: 'pointer'});
                    $('.food-menu__button-right').css({opacity: 1, cursor: 'pointer'});
                }
                changeDishes(currentDish, nextDish, copyDish, nextDishImg);
                copyDish.css({filter: 'blur(2.5px)'});
                nextDish.css({filter: 'blur(0)'});
                setCurrentDishIdx(1)

                setTimeout(() => {
                    changeDishesTimeout(currentDish, currentDishImg, copyDish);
                    isBlocked = false;
                }, 1100)

                whoIsFirst = 'next';
                whoIsLast = 'current';
            } else if(whoIsFirst === 'next') {
                if (isOnlyTwo(dishes[currentMealType])) {
                    isBlocked = false;
                    return;
                }
                changeDishes(nextDish, copyDish, currentDish, copyDishImg);
                currentDish.css({filter: 'blur(2.5px)'});
                copyDish.css({filter: 'blur(0)'});
                setCurrentDishIdx(2)

                setTimeout(() => {
                    changeDishesTimeout(nextDish, nextDishImg, currentDish);
                    isBlocked = false;
                }, 1100)

                whoIsFirst = 'copy';
                whoIsLast = 'next';
            } else if(whoIsFirst === 'copy') {
                changeDishes(copyDish, currentDish, nextDish, currentDishImg);
                nextDish.css({filter: 'blur(2.5px)'});
                currentDish.css({filter: 'blur(0)'});
                setCurrentDishIdx(0)

                setTimeout(() => {
                    changeDishesTimeout(copyDish, copyDishImg, nextDish);
                    isBlocked = false;
                }, 1100)

                whoIsFirst = 'current';
                whoIsLast = 'copy';
            }

        })

        return () => {
            $('.food-menu__button-left').unbind('click');
            $('.food-menu__button-right').unbind('click');
        }
    }, [dishes, currentMealType])

    const mondayRef = useRef(null)

    const handleChangeWeek = useCallback((week) => {
        setWeek(week)
        mondayRef.current && mondayRef.current.click()
    }, [mondayRef, setWeek])

    return (
        <article id="food-menu" className='food-menu-container'>
            <section className='food-menu'>
                <span className='food-menu__header'>{!isEnglish ? 'Меню' : 'Menu'}</span>
                <div className='food-menu__weeks'>
                    <CurrentWeek isEnglish={isEnglish} setWeek={handleChangeWeek} />
                    <NextWeek isEnglish={isEnglish} setWeek={handleChangeWeek} />
                </div>
                <div className='food-menu__days'>
                    <Monday isEnglish={isEnglish} setDayOfWeek={setDayOfWeek} ref={mondayRef} />
                    <Tuesday isEnglish={isEnglish} setDayOfWeek={setDayOfWeek} />
                    <Wednesday isEnglish={isEnglish} setDayOfWeek={setDayOfWeek} />
                    <Thursday isEnglish={isEnglish} setDayOfWeek={setDayOfWeek} />
                    <Friday isEnglish={isEnglish} setDayOfWeek={setDayOfWeek} />
                </div>
                <div className='food-menu__times'>
                    <Breakfast isEnglish={isEnglish} onClick={() => setCurrentMealType("breakfast")}/>
                    <Lunch isEnglish={isEnglish} onClick={() => setCurrentMealType("dinner")}/>
                    <Dinner isEnglish={isEnglish} onClick={() => setCurrentMealType("supper")}/>
                    <Superset isEnglish={isEnglish}/>
                </div>
                {dishes ? <FoodMenuDishes
                    breakfast={dishes.breakfast}
                    dinner={dishes.dinner}
                    supper={dishes.supper}
                    dishes={dishes}
                    currentMealType={currentMealType}
                    currentDishIdx={currentDishIdx}
                    isEnglish={isEnglish}
                /> : null}
                <div className='white-block'/>
                <div className='right-white-block'/>
                <div className='food-menu__button-left food-menu__buttons'>
                    <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 1L1 8.5L10 16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <div className='food-menu__button-right food-menu__buttons'>
                    <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L10 8.5L1 16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </section>
        </article>
    )
}
