import React, {useEffect} from 'react';
import $ from 'jquery';

import button from '../../../public/video-control.png';
import videoPoster from '../../../public/spiroIsQuality/videoPoster.png';

let isPlayed;

const playOrPause = (control, video) => {
    if (!isPlayed) {
        isPlayed = true;
        control.css({opacity: 0});
        video.get(0).play();
        video.attr('controls', true);
    } else {
        video.get(0).pause();
        isPlayed = false;
    }
}

export default function SpiroIsQuality(props) {
    useEffect(() => {
        const control = $('.spiro-is-quality__video-control');
        const video = $('.spiro-is-quality__video video');
        video.click(() => {
            playOrPause(control, video);
        });
        control.click(() => {
            playOrPause(control, video);
        });
    }, [])

    const {video, apiData} = props;
    console.log(apiData)
    return (
        <section className='spiro-is-quality-container'>
            <article className='spiro-is-quality'>
                <div className='spiro-is-quality__text'>
                    <h5>
                        <span dangerouslySetInnerHTML={{__html: apiData.spiro_is_quality_title}}/>
                        <svg width="226" height="4" viewBox="0 0 226 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 3C42.641 2.19259 145.738 0.674648 225 1.06221" stroke="#146957"/>
                        </svg>
                    </h5>
                    <p dangerouslySetInnerHTML={{__html: apiData.spiro_is_quality_under_title}}>
                    </p>
                </div>
                <div className='spiro-is-quality__video'>
                    <video src={video} poster={videoPoster}/>
                    <div className='spiro-is-quality__video-control'>
                        <img src={button}/>
                    </div>
                </div>
            </article>
        </section>
    )
}