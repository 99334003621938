import React, {useState, useCallback} from 'react';
import $ from 'jquery';
import {subscribe} from "../../libs/api";
import ThanksForSubscribe from "./thanksForSubscribe";

export default function AddEmail(props) {
    const { isSpiroLife, spiroLifeSmall } = props
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const handleInputChange = useCallback((e) => {
        setEmail(e.target.value.replace(/[А-Яа-я]/i, ''))
    }, [])
    const handleSubmitPress = useCallback(async (e) => {
        $('.thanks-for-subscribe.thanks-email')
            .css({display: 'flex'})
            .animate({opacity: 1}, 1000);
        e.preventDefault()
        setIsLoading(true);
        setEmail('');
        try {
            await subscribe(email)
        } finally {
            setIsLoading(false)
        }
    }, [email])

    if (isSpiroLife) {
        <ThanksForSubscribe isEnglish={props.isEnglish} className="thanks-email"/>
        return <section className="subscribe_dark">
            <h3>Оставайтесь на связи<small>Подпишитесь на рассылку и получайте полезные советы, статьи и рецепты</small></h3>
            <div className="input-group">
                <input type="text" className="form-control" placeholder="Email" aria-label="Email" aria-describedby="Email" value={email} onChange={handleInputChange} />
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button" id="button-addon2" disabled={isLoading} onClick={handleSubmitPress}>Подписаться</button>
                </div>
            </div>
        </section>
    }

    if (spiroLifeSmall) {
        <ThanksForSubscribe isEnglish={props.isEnglish} className="thanks-email"/>
        return <section className="subscribe_dark col-12  gray_____">
            <h3>Оставайтесь на связи<small>Подпишитесь на рассылку и получайте полезные советы, статьи и рецепты</small></h3>
            <div className="input-group  ">
                <input type="text" className="form-control" placeholder="Email" aria-label="Email" aria-describedby="Email" value={email} onChange={handleInputChange} />
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button" id="button-addon2" disabled={isLoading} onClick={handleSubmitPress}>Подписаться</button>
                </div>
            </div>
        </section>
    }

    return (
        <article className='add-email'>
            <ThanksForSubscribe isEnglish={props.isEnglish} className="thanks-email" />
            <h5>
                {!props.isEnglish ? 'Оставайтесь на связи' : 'Stay in touch'}
            </h5>
            {
                !props.isEnglish
                    ? <p>Подпишитесь на рассылку и получайте полезные советы, статьи и рецепты</p>
                    : <p>Sign up for our newsletter <br/>and get useful info</p>
            }
            <form className="add-email__form">
                <input type="email" className="add-email__input" placeholder="E-mail" value={email} onChange={handleInputChange} />
                <button className="add-email__button" onClick={handleSubmitPress} disabled={isLoading}>{!props.isEnglish ? 'Подписаться' : 'Subscribe'}</button>
            </form>
        </article>
    )
}
