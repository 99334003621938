import React, {Fragment, useEffect} from 'react';
import $ from 'jquery';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {useMediaQuery} from "react-responsive";

export default function DeliveryTime(props) {
    const {apiData, onChangeMon, onChangeWed, valueMon, valueWed} = props;
    const subscribeFormMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });

    const mondayConfig = {
        className: 'subscribe-form__monday',
        options: apiData.apiData.subscribe_form_monday.split(';'),
        placeholder: apiData.apiData.subscribe_form_monday.split(';')[0]
    }

    const wednesdayConfig = {
        className: 'subscribe-form__wednesday',
        options: apiData.apiData.subscribe_form_wednesday.split(';'),
        placeholder: apiData.apiData.subscribe_form_wednesday.split(';')[0]
    }

    const wednesdayConfigMobile = {
        className: 'subscribe-form__wednesday',
        options: apiData.apiData.subscribe_form_wednesday.split(';'),
        placeholder: apiData.apiData.subscribe_form_wednesday.split(';')[0]
    }


    const mondayConfigMobile = {
        className: 'subscribe-form__monday',
        options: apiData.apiData.subscribe_form_monday.split(';'),
        placeholder: apiData.apiData.subscribe_form_monday.split(';')[0]
    }

    useEffect(() => {
        $('.subscribe-form__monday-wednesday').css({width: $('.subscribe-form__delivery-time').width() - $('.subscribe-form__wednesday').width() + 55});

    })

    return (
        <div className='subscribe-form__line' style={!subscribeFormMobile ? {height: 119, opacity: 1, boxSizing: 'border-box', paddingTop: 30} : {height: 150, opacity: 1}}>
            {
                !subscribeFormMobile
                    ? <Fragment>
                        <h6 className='subscribe-form__title' style={{paddingBottom: 0}}>Время<br/>доставки</h6>
                        <span className='subscribe-form__line-monday'>Понедельник</span>
                        <span className='subscribe-form__line-wednesday'>Среда</span>
                    </Fragment>
                    : <Fragment>
                        <h6 className='subscribe-form__title' style={{top: 470, display: 'block'}}>Время доставки</h6>
                        <div className='subscribe-form__monday-wednesday'>
                            <span className='subscribe-form__line-monday'>Понедельник</span>
                            <span className='subscribe-form__line-wednesday'>Среда</span>
                        </div>
                    </Fragment>
            }
            <section className='subscribe-form__delivery-time'>
                {
                    !subscribeFormMobile ? <Fragment>
                        <Dropdown {...mondayConfig} onChange={onChangeMon} value={valueMon}/>
                        <Dropdown {...wednesdayConfig} onChange={onChangeWed} value={valueWed}/>
                    </Fragment> : <Fragment>
                        <Dropdown {...mondayConfigMobile} onChange={onChangeMon} value={valueMon}/>
                        <Dropdown {...wednesdayConfigMobile} onChange={onChangeWed} value={valueWed}/>
                    </Fragment>
                }
            </section>
        </div>
    )
}
