import $ from "jquery";

export default function resetPositions() {
    console.log('im here')
    $('.food-menu__dish-current').css({
        left: 0,
        zIndex: -100,
        width: 418,
        height: 501,
        filter: 'blur(0)'
    });
    $('.food-menu__dish-next').css({
        left: 125,
        zIndex: -1000,
        width: 370,
        height: 442,
        filter: 'blur(2.5px)'
    });
    $('.food-menu__dish-copy').css({
        left: 525,
        zIndex: -2000,
        width: 370,
        height: 442,
        filter: 'blur(2.5px)'
    });

    $('.food-menu__dish-current .food-menu__first-image img').css({
        width: 418,
        height: 418
    });

    $('.food-menu__dish-next .food-menu__first-image img').css({
        width: 370,
        height: 370
    });

    $('.food-menu__dish-copy .food-menu__first-image img').css({
        width: 370,
        height: 370
    });
}