import React from 'react';
import $ from 'jquery';
import './style.css';

export default function ThanksForSubscribe(props) {
    const {isEnglish, className} = props;
    return (
        <div className={`thanks-for-subscribe ${className || ''}`}>
            <h5>{!isEnglish ? 'Спасибо!' : 'Thank you!'}</h5>
            <p>{!isEnglish ? 'Мы получили ваши данные!' : 'We received your data'}</p>
            <button onClick={(e) => {
                e.preventDefault();
                $('.thanks-for-subscribe').animate({opacity: 0}, 1000);
                setTimeout(() => {
                    $('.thanks-for-subscribe').css({display: 'none'})
                }, 1200);
            }}>{!isEnglish ? 'Замечательно' : 'Wonderful'}</button>
        </div>
    )
}