import React from 'react';
import {ParallaxProvider, Parallax} from "react-scroll-parallax";

import MainForm from "./subscribeFormMainBlock";
import {useMediaQuery} from "react-responsive";

import notAllField from '../../../public/subscribeForm/notAllFields.png';

export default function SubscribeForm(props) {
    const {apiData} = props;
    const {isEnglish} = apiData.apiData;
    const mobileVersion = useMediaQuery({
        query: '(max-width: 992px)'
    })

    return (
        <ParallaxProvider>
            <section className="subscribe-form-container">
                {
                    !mobileVersion ? <Parallax y={[10, -10]}>
                        <div className='subscribe'>
                            <form className='subscribe-form'>
                                <MainForm apiData={apiData}/>
                            </form>
                            <div className='subscribe-form__not-all-fields'>
                                <img src={notAllField}/>
                                <h5>{!isEnglish ? 'Заполнены не все поля' : 'Missed fields'}</h5>
                                <p>{!isEnglish ? 'Для оформления подписки заполните, пожалуйста, все данные' : 'To subscribe please fill all the data'}</p>
                            </div>
                        </div>
                    </Parallax> : <div className='subscribe'>
                        <form className='subscribe-form'>
                            <MainForm apiData={apiData}/>
                        </form>
                        <div className='subscribe-form__not-all-fields'>
                            <div className='subscribe-form__not-all-fields-cloud'/>
                            <h5>{!isEnglish ? 'Заполнены не все поля' : 'Missed fields'}</h5>
                            <p>{!isEnglish ? 'Для оформления подписки заполните, пожалуйста, все данные' : 'To subscribe please fill all the data'}</p>
                        </div>
                    </div>
                }
            </section>
        </ParallaxProvider>
    )
}
