import React from 'react';
import $ from 'jquery';

import hippocratesEllipse from '../../../public/ellipse-hippocrates.png';
import hippocratesImage from '../../../public/hippocrates-image.png';
import spiroSymbol from '../../../public/foodAsMedicine/symbol-spiro.svg';

export default function Hippocrates(props) {
    const {setIsDisablePhone, apiData} = props;
    return (
        <article className='hippocrates'>
            <div className='hippocrates__text'>
                <img src={spiroSymbol}/>
                <h5 dangerouslySetInnerHTML={{__html: apiData.hippocrates_title}}/>
                <p dangerouslySetInnerHTML={{__html: apiData.hippocrates_under_title}}/>
            </div>
            <div className='hippocrates__image'>
                <img src={hippocratesEllipse}/>
                <img src={hippocratesImage}/>;
            </div>
            <div className='footer__up' onClick={() => {
                setIsDisablePhone(true);
                setTimeout(() => setIsDisablePhone(false), 4000)
                $('html, body').animate({scrollTop: 0}, 2000);
            }}>
                <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 10L10 1L19 10" stroke="black"/>
                </svg>
            </div>
        </article>
    )
}