import React, {Fragment, useMemo} from 'react';
// import {useMediaQuery} from 'react-responsive';
import MediaQuery from "react-responsive";
import './heathInfoMobile.css'

import firstImage from '../../../public/healthInfo/healthInfoMobile/1.png'
import secondImage from '../../../public/healthInfo/healthInfoMobile/2.png';
import thirdImage from '../../../public/healthInfo/healthInfoMobile/3.png';
import fourthImage from '../../../public/healthInfo/healthInfoMobile/4.png';

const sliderSettings = {
    className: 'health-info-mobile__slider',
    dots: true,
    infinite: false,
    slidesToShow: 1,
    swipeToSlide: true,
    customPaging: (i) => {
        return (
            <a>
                <div className='health-info-mobile__dot'></div>
            </a>
        )},
    dotsClass: 'slick-dots'
}

export default function HealthInfoMobile(props) {
    const {isEnglish} = props.apiData;
    return (
            <section className='health-info-mobile-container'>
                <article className='health-info-mobile'>
                    <h1 className='health-info__header'>
                        {
                            !isEnglish
                                ?
                                <Fragment>
                                    <p>
                                        За неделю питания
                                        <svg width="185" height="5" viewBox="0 0 180 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 4C38.2785 2.43156 126.068 -0.234777 179 1.64735" stroke="#146957"/>
                                        </svg>
                                    </p> в&nbsp;программе Spirocooking ваше тело получит <span>200+&nbsp;ингредиентов для&nbsp;здоровья</span>
                                </Fragment>
                                :
                                <Fragment>
                                    <p>
                                        Every week with
                                    </p> Spirocooking, your body receives <span>200+ ingredients for good health</span>
                                </Fragment>
                        }
                    </h1>
                    <MediaQuery maxDeviceWidth={992}>
                        <div className={'device-460'} style={{marginTop: 70}}>
                            <img src={firstImage} className={'device-460__img'}/>
                            <div className={'device-460__ellipse device-460__ellipse_first'}/>
                            <h6 className={'health-info__title'} dangerouslySetInnerHTML={{__html: props.apiData.health_info_first_main}}/>
                            <p className={'health-info__description'} dangerouslySetInnerHTML={{__html: props.apiData.health_info_first_under_main}}/>
                        </div>
                        <div className={'device-460'}>
                            <img src={secondImage} className={'device-460__img'}/>
                            <div className={'device-460__ellipse device-460__ellipse_second'}/>
                            <h6 className={'health-info__title health-info__title_small'} dangerouslySetInnerHTML={{__html: props.apiData.health_info_second_main}}/>
                            <p className={'health-info__description'} dangerouslySetInnerHTML={{__html: props.apiData.health_info_second_under_main}}/>
                        </div>
                        <div className={'device-460'}>
                            <img src={thirdImage} className={'device-460__img'} style={{transform: 'translateY(-20px)'}}/>
                            <div className={'device-460__ellipse device-460__ellipse_third'}/>
                            <h6 className={'health-info__title'} dangerouslySetInnerHTML={{__html: props.apiData.health_info_third_main}}/>
                            <p className={'health-info__description'} dangerouslySetInnerHTML={{__html: props.apiData.health_info_third_under_main}}/>
                        </div>
                        <div className={'device-460'}>
                            <img src={fourthImage} className={'device-460__img'}/>
                            <div className={'device-460__ellipse device-460__ellipse_fourth'}/>
                            <h6 className={'health-info__title'} dangerouslySetInnerHTML={{__html: props.apiData.health_info_fourth_main}}/>
                            <p className={'health-info__description'} dangerouslySetInnerHTML={{__html: props.apiData.health_info_fourth_under_main}}/>
                        </div>
                    </MediaQuery>
                </article>
            </section>
    )
}