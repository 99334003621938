import React from 'react';
import {ParallaxProvider} from "react-scroll-parallax";
import {Parallax} from "react-scroll-parallax";

import First from "./first";
import Second from "./second";
import Third from "./third";
import Fourth from "./fourth";

import firstFlyFruit from './flyFruit.png';
import secondFlyFruit from './secondFlyFruit.png';

export default function HealthInfo(props) {
    const {apiData} = props;
    return (
        <section className='health-info'>
            <h1 className='health-info__header' dangerouslySetInnerHTML={{__html: apiData.health_info_title}}>
            </h1>
            <ParallaxProvider>
                <Parallax className='flying-fruits' y={[100, -60]}>
                    {/*<div className='flying-fruits'>*/}
                        <img src={firstFlyFruit}/>
                        <img src={secondFlyFruit}/>
                    {/*</div>*/}
                </Parallax>
                <First apiData={apiData}/>
                <Second apiData={apiData}/>
                <Third apiData={apiData}/>
                <Fourth apiData={apiData}/>
            </ParallaxProvider>
        </section>
    )
}