import React, {useEffect, useState} from 'react';
import $ from 'jquery';
import {useMediaQuery} from "react-responsive";
import Slider from 'react-slick';
import Faq from 'react-faq-component';

import button from '../../../public/questions-answers-button.png';

// const dataFaq = {
//     title: "FAQ (How it works)",
//     rows: [
//         {
//             title: "Сколько хранятся блюда?",
//             content: `Spiro это не новый тренд, не хайп, не веганство или ЗОЖ – это то, что изначально должно было быть. Возвращение к истокам. Гармоничная эволюция, к которой придут все, просто кто-то раньше`,
//         },
//         {
//             title: "В какие дни доставляете еду?",
//             content: 'Spiro это не новый тренд, не хайп, не веганство или ЗОЖ – это то, что изначально должно было быть. Возвращение к истокам. Гармоничная эволюция, к которой придут все, просто кто-то раньше',
//         },
//         {
//             title: "Что приходит в каждой доставке?",
//             content: 'Spiro это не новый тренд, не хайп, не веганство или ЗОЖ – это то, что изначально должно было быть. Возвращение к истокам. Гармоничная эволюция, к которой придут все, просто кто-то раньше',
//         },
//         {
//             title: "Как работает подписка Spirocooking",
//             content: 'Spiro это не новый тренд, не хайп, не веганство или ЗОЖ – это то, что изначально должно было быть. Возвращение к истокам. Гармоничная эволюция, к которой придут все, просто кто-то раньше',
//         },
//         {
//             title: "Как работает подписка?",
//             content: 'Spiro это не новый тренд, не хайп, не веганство или ЗОЖ – это то, что изначально должно было быть. Возвращение к истокам. Гармоничная эволюция, к которой придут все, просто кто-то раньше',
//         },
//         {
//             title: "Хочу скидку! Можно?",
//             content: 'Spiro это не новый тренд, не хайп, не веганство или ЗОЖ – это то, что изначально должно было быть. Возвращение к истокам. Гармоничная эволюция, к которой придут все, просто кто-то раньше',
//         }
//     ]
// }

const configFaq = {
    arrowIcon: <img src={button}/>,
    animate: true
}

export default function QuestionsAndAnswers(props) {
    const {apiData} = props;
    const dataFaq = {
        title: 'FAQ',
        rows: []
    };
    for (let element of apiData.faq) {
        element = element.split('///');
        const title = element[0];
        const content = element[1];
        dataFaq.rows.push({title: title, content: content})
    }
    const questionAndAnswerMobile = useMediaQuery({
        query: '(max-width: 767px)'
    })
    useEffect(() => {
        if (questionAndAnswerMobile) return;
        $('.questions-and-answers__block').click(function(){
            const image = $(this).find('img');
            if (image.css('transform') === 'matrix(-1, 1.22465e-16, -1.22465e-16, -1, 0, 0)') {
                image.css({transform: 'rotate(0deg)'});
                image.siblings('p').css({display: 'none', opacity: 0});
            } else {
                image.css({transform: 'rotate(180deg)'});
                image.siblings('p').css({display: 'block'});
                image.siblings('p').animate({opacity: 1}, 500);
            }
        });
    }, [])
    return (
        <article className={!questionAndAnswerMobile ? 'questions-and-answers' : 'questions-and-answers-mobile'}>
            <h5 className='questions-and-answers__header'>
                Вопросы и ответы
            </h5>
            {
                !questionAndAnswerMobile
                    ? <Faq data={dataFaq} config={configFaq}/>
                    : <div className='questions-and-answers-mobile__slider-container'>
                        <Slider infinite={true} className='questions-and-answers-mobile__carousel' slidesToShow={2} slidesToScroll={1} >
                            {
                                apiData.faq.map(el => {
                                    const element = el.split('///');
                                    return (
                                        <div className='questions-and-answers-mobile__block'>
                                            <h6 dangerouslySetInnerHTML={{__html: element[0]}}></h6>
                                            <p dangerouslySetInnerHTML={{__html: element[1]}}>
                                            </p>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>
            }
        </article>
    )
}