import * as THREE from "three";

export default function RenderMobilePhone(getIsUpdatePhone, image1, image2, image3, image4, image5, image6, objIphone, getIsDisablePhone) {
    const renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(15, 1, 1, 2000);

    camera.position.set(15, -10, 900)

    renderer.setSize(600, 600);
    renderer.setClearColor(0x000000, 0);
    renderer.setPixelRatio(window.devicePixelRatio)

    document.getElementById('iphone-model').appendChild(renderer.domElement);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(0, 0, 1);
    scene.add(directionalLight)

    const texture = new THREE.Texture();

    let mesh;

    texture.image = image1;
    texture.needsUpdate = true;

    scene.add(objIphone);

    objIphone.traverse(child => {
        if (child instanceof THREE.Mesh) {
            child.material[1] = new THREE.MeshBasicMaterial({
                map: texture
            })
        }
    })

    mesh = objIphone;
    objIphone.rotation.y = .5;
    objIphone.rotation.x = -.25;
    objIphone.rotation.z = .25

    let isOldFirst, isOldSecond, isOldThird, isOldFourth, isOldFifth, isOldSixth;

    // let disableOnes;

    const rotatePhone = () => {
        if (isOldSecond || isOldFourth || isOldSixth) {
            mesh.rotation.y > -.5 ? mesh.rotation.y -= 0.0125 : '';
            mesh.rotation.x > .25 ? mesh.rotation.x -= 0.00625 : '';
            mesh.rotation.z > -.25 ? mesh.rotation.z -= 0.00625 : '';
        } else if (isOldThird || isOldFirst || isOldFifth) {
            mesh.rotation.y < .5 ? mesh.rotation.y += 0.0125 : '';
            mesh.rotation.x < -.25 ? mesh.rotation.x += 0.00625 : '';
            mesh.rotation.z < .25 ? mesh.rotation.z += 0.00625 : '';
        }
    }

    let disableOnes;

    const rendering = () => {
        requestAnimationFrame(rendering);
        renderer.render(scene, camera);

        const {isFirst, isSecond, isThird, isFourth, isFifth, isSixth} = getIsUpdatePhone();


        if (getIsDisablePhone()) {
            isOldSixth = false;
            mesh.rotation.y = .5;
            mesh.rotation.x = -.25;
            mesh.rotation.z = .25;
        }

        rotatePhone();

        if (isFirst == isOldFirst && isSecond == isOldSecond && isThird == isOldThird && isFourth == isOldFourth && isFifth
         == isOldFifth && isSixth == isOldSixth) return;


        isOldFirst = isFirst;
        isOldSecond = isSecond;
        isOldThird = isThird;
        isOldFourth = isFourth;
        isOldFifth = isFifth;
        isOldSixth = isSixth;

        if (isFirst) {
            texture.image = image1;
            texture.needsUpdate = true;
        } else if (isSecond) {
            texture.image = image2;
            texture.needsUpdate = true;
        } else if (isThird) {
            texture.image = image3;
            texture.needsUpdate = true;
        } else if (isFourth) {
            texture.image = image4;
            texture.needsUpdate = true;
        } else if (isFifth) {
            texture.image = image5;
            texture.needsUpdate = true;
        } else if (isSixth) {
            texture.image = image6;
            texture.needsUpdate = true;
        }
    }

    rendering();
}