import React, {Fragment, useEffect} from 'react';
import $ from "jquery";
import {useMediaQuery} from "react-responsive";

export default function Superset(props) {
    const {mark, isEnglish} = props;

    const mobileVersion = useMediaQuery({
        query: '(max-width: 992px)'
    });

    useEffect(() => {
        const beauty = $('.subscribe-form__beauty');
        const performance = $('.subscribe-form__performance');

        const beautyCircle = beauty.find('.subscribe-form__circle');
        const performanceCircle = performance.find('.subscribe-form__circle');

        let beautyIsActive, performanceIsActive;

        let oneClickBeauty;
        let oneClickPerformance;

        let isClicked;
        let isActiveSuperset;
        $('.subscribe-form__active-superset').click(function () {
            if (!isActiveSuperset) {
                if ($(this).find('.subscribe-form__rectangle').css('background-color') !== 'rgba(0, 0, 0, 0)') {
                    $(this).find('.subscribe-form__rectangle').css({background: '#146957', border: '1px solid #146957'});
                }
                $(this).find('.subscribe-form__checked-mark').css({opacity: 1});
                isActiveSuperset = true;
                beauty.css({opacity: 1});
                performance.css({opacity: 1});
            } else {
                if ($(this).find('.subscribe-form__rectangle').css('background-color') !== 'rgba(0, 0, 0, 0)') {
                    $(this).find('.subscribe-form__rectangle').css({background: '#fff', border: '1px solid #1d1d1d'});
                }
                $(this).find('.subscribe-form__checked-mark').css({opacity: 0});
                isActiveSuperset = false;
                beauty.css({opacity: .5});
                performance.css({opacity: .5});
                beautyIsActive = false;
                performanceIsActive = false;
                $('.subscribe-form__beauty .subscribe-form__checked-mark').css({opacity: 0});
                if (beautyCircle.css('background-color') !== 'rgba(0, 0, 0, 0)') {
                    beautyCircle.css( {background: '#fff', border: '1px solid #1d1d1d'} );
                    $('.subscribe-form__input-beauty').prop('checked', false);
                }
                $('.subscribe-form__performance .subscribe-form__checked-mark').css({opacity: 0});
                if (performanceCircle.css('background-color') !== 'rgba(0, 0, 0, 0)') {
                    performanceCircle.css( {background: '#fff', border: '1px solid #1d1d1d'} );
                    $('.subscribe-form__input-performance').prop('checked', false);
                }
            }
        })

        beauty.click(() => {
            if (!isActiveSuperset) return;
            if (isClicked) return;
            isClicked = true;
            setTimeout(() => isClicked = false, 400);
            if (oneClickBeauty) {
                return;
            }
            oneClickBeauty = true;
            setTimeout(() => {
                oneClickBeauty = false;
            }, 200);
            if (beautyIsActive) {
                beautyIsActive = false;
                $('.subscribe-form__beauty .subscribe-form__checked-mark').css({opacity: 0});
                if (beautyCircle.css('background-color') !== 'rgba(0, 0, 0, 0)') {
                    beautyCircle.css( {background: '#fff', border: '1px solid #1d1d1d'} );
                    $('.subscribe-form__input-beauty').prop('checked', false);
                }
                return;
            }
            beautyIsActive = true;
            performanceIsActive = false;
            if (beautyCircle.css('background-color') !== 'rgba(0, 0, 0, 0)') {
                beautyCircle.css( {background: '#146957', border: '1px solid #146957'} );
                performanceCircle.css( {background: '#fff', border: '1px solid #1d1d1d'} );
            }
            $('.subscribe-form__performance .subscribe-form__checked-mark').css({opacity: 0});
            $('.subscribe-form__beauty .subscribe-form__checked-mark').css({opacity: 1, width: 28, height: 20});
            $('.subscribe-form__beauty .subscribe-form__checked-mark').animate({opacity: 1, width: 14, height: 10}, 100);
            $('.subscribe-form__input-beauty').prop('checked', true);
        })
        performance.click(() => {
            if (!isActiveSuperset) return;
            if (isClicked) return;
            isClicked = true;
            setTimeout(() => isClicked = false, 400);
            if (oneClickPerformance) {
                return;
            }
            oneClickPerformance = true;
            setTimeout(() => {
                oneClickPerformance = false;
            }, 200);
            if (performanceIsActive) {
                performanceIsActive = false;
                $('.subscribe-form__performance .subscribe-form__checked-mark').css({opacity: 0});
                if (performanceCircle.css('background-color') !== 'rgba(0, 0, 0, 0)') {
                    performanceCircle.css( {background: '#fff', border: '1px solid #1d1d1d'} );
                    $('.subscribe-form__input-performance').prop('checked', false);
                }
                return;
            }
            performanceIsActive = true;
            beautyIsActive = false;
            if (performanceCircle.css('background-color') !== 'rgba(0, 0, 0, 0)') {
                performanceCircle.css( {background: '#146957', border: '1px solid #146957'} );
                beautyCircle.css( {background: '#fff', border: '1px solid #1d1d1d'} );
            }
            $('.subscribe-form__beauty .subscribe-form__checked-mark').css({opacity: 0});
            $('.subscribe-form__performance .subscribe-form__checked-mark').css({opacity: 1, width: 28, height: 20});
            $('.subscribe-form__performance .subscribe-form__checked-mark').animate({opacity: 1, width: 14, height: 10}, 100);
            $('.subscribe-form__input-performance').prop('checked', true);
        })
    }, [])

    return (
        <div className='subscribe-form__line' style={!mobileVersion ? {height: 109, position: 'relative'} : {height: 104}}>
            <h6 className='subscribe-form__title' style={
                !mobileVersion ? {width: 109, marginTop: 10} : {top: 275}
            }>{
                !mobileVersion ? <Fragment>Super<br/>Set</Fragment> : <Fragment></Fragment>
            }</h6>
            <label className='subscribe-form__active-superset'>
                <div className='subscribe-form__rectangle'>
                    <img className='subscribe-form__checked-mark' src={mark}/>
                </div>
                {!isEnglish ? <span>Включить Superset</span> : <span>Add Superset&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                {/*<span>{!isEnglish ? 'Включить' : 'Add'} Superset {!isEnglish ? '' : '      '}</span>*/}
            </label>
            <label className='subscribe-form__label subscribe-form__beauty' style={
                !mobileVersion
                    ? {width: 87, height: 26, marginLeft: 15}
                    : {width: 75, height: 22, marginLeft: 0, marginTop: 35}
            }>
                <div className='subscribe-form__circle'>
                    <img className='subscribe-form__checked-mark' src={mark}/>
                </div>
                <span>Beauty</span>
                <input className='subscribe-form__input-beauty' type='radio' name='superset' value='beauty'/>
            </label>
            <label className='subscribe-form__label subscribe-form__performance' style={
                !mobileVersion
                    ? {width: 126, height: 27, marginLeft: 33}
                    : {width: 118, height: 21, marginLeft: '7%', marginTop: 35}
            }>
                <div className='subscribe-form__circle'>
                    <img className='subscribe-form__checked-mark' src={mark}/>
                </div>
                <span>Performance</span>
                <input className='subscribe-form__input-performance' type='radio' name='superset' value='performance'/>
            </label>
        </div>
    )
}