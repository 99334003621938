import * as THREE from 'three';
import $ from "jquery";

let canRotate = false;
let dishIsLoaded;
let rendering;

const showFixedHeader = () => {
    $('.header-mobile_fixed').animate({opacity: 1}, 500);
    window.removeEventListener('touchmove', showFixedHeader)
}

// $('#app').css({overflow: 'hidden', position: 'relative'});

let mesh;

const scrollDown = (event) => {
    $('.spiro-blick').css({display: 'block'})
    $('html, body').animate({scrollTop: $('.food-as-medicine__main-text').offset().top - 40 }, 100);
    $('.spiro-blick').css({display: 'block'})
    $('.spiro-blick').css({opacity: 1}, 500);
    $('.spiro-blick').animate({left: '100vw'}, 1400, 'linear');
    $('.header-mobile_fixed').css({display: 'flex'});
    setTimeout(() => {
        $('.food-as-medicine__main-text').animate({opacity: 1}, 500);
        $('.food-as-medicine__text-under-main-text').animate({opacity: 1}, 500);
        $('.food-as-medicine__mobile-text').animate({opacity: 1}, 500);
        $('.food-as-medicine_little-text, .first-screen__title, .first-screen__text, .watch-video').animate({opacity: 1}, 500);
        $('.blue-ellipse').css({background: '#f3f8f7'});
        $('.first-screen__static-dish').css({display: 'flex'});
        $('#app').css({overflowY: 'scroll'});
        $('.food-menu__second-image, .food-menu__third-image').css({
            position: 'absolute', top: 0, opacity: 0, transition: 'opacity .5s'
        });
        $('#dish').css({opacity: 1, position: 'absolute', top: 380});
        mesh.rotation.x = .85;
        mesh.rotation.y = -2.775;
        mesh.rotation.z = 0.25;
    }, 1300);
    setTimeout(() => {
        window.addEventListener('touchmove', showFixedHeader)
    }, 2000)
    setTimeout(() => {
        $('.dish-with-dessert-static').css({opacity: 1});
        document.getElementsByClassName('dish__flying-dish')[0].remove();
        $('body').css({'overflow-y': 'scroll'});
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {

            } else {
                $('html, body').css({'overflow-y': 'scroll'});
                // $('.food-menu__dish img').attr('src', $('.food-menu__dish img').data('image'));
                $('.food-menu__dish img').each(function () {
                    $(this).attr('src', $(this).data('image'))
                })
                const healthInfoSlider = document.getElementsByClassName('health-info-mobile__slider');
                for (let i = 0; i < healthInfoSlider.length; i++) {
                    const healthInfoImage = $(healthInfoSlider[i]).find('img');
                    healthInfoImage.attr('src', healthInfoImage.data('image'));
                }
            }
        }
    }, 2000);
    $('#dish').animate({opacity: 0, top: -500}, 800);
    const flyingDish = $('.dish__flying-dish');
    flyingDish.css({
        display: 'flex',
        position: 'fixed',
        top: $('#dish').position().top,
        left: 'calc(50% - 160px)'
    });
    flyingDish.animate({opacity: 1}, 500);
    setTimeout(() => {
        $('.dish__table-dish').animate({opacity: 1, top: $('.dish__table-dish').position().top + 186}, 800);
        $('.dish__dessert').animate({opacity: 0 , top: $('.dish__table-dish').position().top + 186}, 500);
    }, 0);
}

let isDisableAnimationPack;

function animation() {
    if (isDisableAnimationPack) return;
    const dish = $('#dish');
    $(dish).css({position: 'fixed', top: dish.position().top, left: dish.position().left - 15});
    $('.first-screen__title, .first-screen__text, .watch-video').animate({opacity: 0}, 200);
    $('.header-mobile').animate({opacity: 0}, 200);

    canRotate = true;

    // rendering();

    window.removeEventListener('touchmove', animation);
}

export default function packMobile(props) {
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    const camera = new THREE.PerspectiveCamera(15, 1, 1300, 2000);
    const scene = new THREE.Scene();

    renderer.setSize(320, 320);
    renderer.setClearColor(0x000000, 0);
    renderer.setPixelRatio(window.devicePixelRatio);
    document.getElementById('dish').appendChild(renderer.domElement);

    camera.position.set(0,32, 1500); //z=1500

    const sunLightIntensity = 1.939;

    const sunLight = new THREE.DirectionalLight(0xfbf7ff, sunLightIntensity);
    const leftDirectionalLight = new THREE.DirectionalLight(0xffffff, .9);
    const rightDirectionalLight = new THREE.DirectionalLight(0xf9fbfd, .6);

    sunLight.position.set(30, 15, 0);
    leftDirectionalLight.position.set(-1, 0, 0);
    rightDirectionalLight.position.set(1, -7, 0);

    scene.add(sunLight);
    scene.add(leftDirectionalLight);
    scene.add(rightDirectionalLight);

    const texture = new THREE.Texture();

    texture.image = props.image;
    texture.needsUpdate = true;

    const obj = props.obj;

    const {disablePackAnimation} = props;

    obj.traverse(child => {
        if (child instanceof THREE.Mesh) {
            child.material = new THREE.MeshLambertMaterial({
                map: texture,
            })
        }
    })

    scene.add(obj);
    obj.rotation.x += .85;
    obj.rotation.y += -2.775;
    obj.rotation.z += 0.25;
    mesh = obj;

    let onlyOnces = true;

    let requestId;

    let count = 0;
    renderer.render(scene, camera);
    rendering = () => {
        requestId = requestAnimationFrame(rendering);
        renderer.render(scene, camera);
        isDisableAnimationPack = disablePackAnimation();
        if (disablePackAnimation()) {
            $('.header-mobile_fixed').css({opacity: 1, display: 'flex'})
            // $('.site-navigation-after-scroll').css({display: 'block', opacity: 1});
            cancelAnimationFrame(requestId);
            $('.blue-ellipse').css({background: 'rgb(243, 248, 247)'});
            $('.food-as-medicine__main-text, .food-as-medicine__text-under-main-text, .dish-with-dessert-static').css({opacity: 1});
            return;
        }

        if (mesh && onlyOnces) {
            setTimeout(() => {
                $('.container-start-info').animate({opacity: 1}, 1000);
                $('#dish').animate({opacity: 1}, 1000)
                setTimeout(() => dishIsLoaded = true, 1000);
            }, 1000);
            onlyOnces = false;
        }

        if (canRotate) {
            if (mesh.rotation.x < 1.45) {
                count++;

                mesh.rotation.x += 0.04;
                leftDirectionalLight.position.x -= 0.034;
                leftDirectionalLight.position.z += 0.034;
                leftDirectionalLight.intensity += 0.07;
            }
            if (mesh.rotation.y < -1.6) {
                mesh.rotation.y += 0.04;
            }
            const dish = $('#dish');
            if (dish.position().top !== 0) {
                for (let i = 0; i < 6; i++) {
                    dish.css({top: dish.position().top -= 2});
                }
            }

            if (mesh.rotation.x >= 1.45 && mesh.rotation.y >= -1.6 && $('#dish').position().top <= 0) {
                canRotate = false;
                scrollDown();
                setTimeout(() => {
                    cancelAnimationFrame(requestId);
                }, 5000)
            }
        }
    }

    rendering();

    window.addEventListener('touchmove', animation);
}
