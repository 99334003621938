import React from "react";
import $ from "jquery";
import resetPositions from "../resetPositions";

export default function Tuesday(props) {
    const changeActivity = () => {
        $('.food-menu__tuesday .food-menu__user-choice').css({opacity: 1});
        $('.food-menu__tuesday span').css({fontWeight: 'bold'});

        $('.food-menu__friday .food-menu__user-choice').css({opacity: 0});
        $('.food-menu__friday span').css({fontWeight: 'normal'});
        $('.food-menu__thursday .food-menu__user-choice').css({opacity: 0});
        $('.food-menu__thursday span').css({fontWeight: 'normal'});
        $('.food-menu__wednesday .food-menu__user-choice').css({opacity: 0});
        $('.food-menu__wednesday span').css({fontWeight: 'normal'});
        $('.food-menu__monday .food-menu__user-choice').css({opacity: 0});
        $('.food-menu__monday span').css({fontWeight: 'normal'});

        props.setDayOfWeek && props.setDayOfWeek(2);

        resetPositions();

        // $('.food-menu__dish *').css({opacity: 0});
        // setTimeout(() => {
        //     setTimeout(() => $('.food-menu__dish *').css({opacity: 1}), 10);
        // }, 0);
    }

    return (
        <div className='food-menu__tuesday' onClick={changeActivity}>
            <div style={{opacity: 0}} className='food-menu__user-choice'/>
            <span>{!props.isEnglish ? 'Вторник' : 'Tuesday'}</span>
        </div>
    )
}
