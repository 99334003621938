import React, {Fragment, useEffect} from 'react';
import $ from "jquery";
import {useMediaQuery} from "react-responsive";

export default function ProgramType(props) {
    const {mark, isEnglish} = props;

    const mobileVersion = useMediaQuery({
        query: '(max-width: 992px)'
    });

    useEffect(() => {
        const everyWeek = $('.subscribe-form__every-week');
        const trialVersion = $('.subscribe-form__trial-version');

        const everyWeekCircle = everyWeek.find('.subscribe-form__circle');
        const trialVersionCircle = trialVersion.find('.subscribe-form__circle');

        let isClicked;

        everyWeek.click(() => {
            if (isClicked) return;
            isClicked = true;
            setTimeout(() => isClicked = false, 400);
            if (everyWeekCircle.css('background-color') !== 'rgba(0, 0, 0, 0)') {
                everyWeekCircle.css( {background: '#146957', border: '1px solid #146957'} );
                trialVersionCircle.css( {background: '#fff', border: '1px solid #1d1d1d'} );
            }
            $('.subscribe-form__trial-version .subscribe-form__checked-mark').css({opacity: 0});
            $('.subscribe-form__every-week .subscribe-form__checked-mark').css({opacity: 1, width: 28, height: 20});
            $('.subscribe-form__every-week .subscribe-form__checked-mark').animate({opacity: 1, width: 14, height: 10}, 100);
            $('.subscribe-form__input-every').prop('checked', true);
        })
        trialVersion.click(() => {
            if (isClicked) return;
            isClicked = true;
            setTimeout(() => isClicked = false, 400);
            if (trialVersionCircle.css('background-color') !== 'rgba(0, 0, 0, 0)') {
                trialVersionCircle.css( {background: '#146957', border: '1px solid #146957'} );
                everyWeekCircle.css( {background: '#fff', border: '1px solid #1d1d1d'} );
            }
            $('.subscribe-form__every-week .subscribe-form__checked-mark').css({opacity: 0});
            $('.subscribe-form__trial-version .subscribe-form__checked-mark').css({opacity: 1, width: 28, height: 20});
            $('.subscribe-form__trial-version .subscribe-form__checked-mark').animate({opacity: 1, width: 14, height: 10}, 100);
            $('.subscribe-form__input-trial').prop('checked', true);
        })
    }, [])

    return (
        <div className='subscribe-form__line'
             style={{height: 171, alignItems: 'flex-start', flexWrap: 'wrap'}}>
            <h6 className='subscribe-form__title'
                style={
                    !mobileVersion
                        ? {width: 109, alignSelf: 'flex-start', top: -15, position: 'relative'}
                        : {position: 'absolute', top: 380}
                }>{
                    !mobileVersion
                        ? !isEnglish ? <Fragment>Тип<br/>программы</Fragment> : <Fragment>Program<br/>type</Fragment>
                        : !isEnglish ? <Fragment> программы</Fragment> : <Fragment>Program type</Fragment>
            }</h6>
            <label className='subscribe-form__label subscribe-form__every-week'
                   style={
                       !mobileVersion
                           ? {height: 54, marginLeft: 15, marginTop: 20}
                           : {width: 'calc(100vw - 20px)',
                               height: 54,
                               marginLeft: 0,
                               marginTop: 40,
                               justifyContent: 'flex-start'}
                   }>
                <div className='subscribe-form__circle '>
                    <img className='subscribe-form__checked-mark' src={mark}/>
                </div>
                <span style={{marginLeft: 10, marginTop: 15}}>
                    {!isEnglish ? 'Еженедельная подписка' : 'Weekly subscription'}
                    <span style={mobileVersion ? {opacity: .5} : {}} className='subscribe-form__type-program-description'>
                        {!isEnglish ? 'Доставка еды каждую неделю' : 'Weekly delivery'}
                    </span>
                </span>
                <input className='subscribe-form__input-every' type='radio' name='programType' value='every week'/>
            </label>
            <label className='subscribe-form__label subscribe-form__trial-version'
                   style={
                       !mobileVersion
                           ? { height: 54, marginTop: -40, marginLeft: 125}
                           : {width: 'calc(100vw - 20px)',
                               height: 54,
                               marginTop: 0,
                               marginLeft: 0,
                               justifyContent: 'flex-start',
                               top: -20
                           }
                   }>
                <div className='subscribe-form__circle '>
                    <img className='subscribe-form__checked-mark' src={mark}/>
                </div>
                <span style={{marginLeft: 10, marginTop: 15}}>
                    {!isEnglish ? 'Пробная подписка' : 'Trial subscription'}
                    <span style={mobileVersion ? {opacity: .5} : {}} className='subscribe-form__type-program-description'>
                        {!isEnglish ? 'Попробовать на неделю' : 'try for a week'}
                    </span>
                </span>
                <input className='subscribe-form__input-trial' type='radio' name='programType' value='trial version'/>
            </label>
        </div>
    )
}