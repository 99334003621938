import React, {useCallback, useEffect} from 'react';
import $ from "jquery";
import {useMediaQuery} from "react-responsive";
import burger from "../../public/burger.svg";

const scrollToLocation = (location) => {
    $('html, body').animate({scrollTop: $(location).offset().top - 100}, 1000);
    $('html, body').css({overflow: 'scroll'});
    $('.desktop-burger').css({opacity: 1, display: 'block'});
    $('.desktop-burger-close').css({opacity: 0, display: 'none'});
    $('.site-navigation-after-scroll').css({opacity: 0});
    setTimeout(() => {
        $('.site-navigation-after-scroll').css({display: 'none'})
    }, 500);
}


export default function SiteNavigationAfterScroll(props) {
    const mobileVersion = useMediaQuery({
        query: '(max-width: 768px)'
    })
    const {setIsDisablePhone} = props;
    const handleScrollToMenu = useCallback(() => {
        setIsDisablePhone(true);
        setTimeout(() => setIsDisablePhone(false), 4000)
        !mobileVersion ? scrollToLocation('.food-menu-container') : scrollToLocation('.food-menu-mobile-container')
    }, [mobileVersion]);
    const handleScrollToSubscribe = useCallback(() => {
        setIsDisablePhone(true);
        setTimeout(() => setIsDisablePhone(false), 4000)
        scrollToLocation('.subscribe')
    }, [mobileVersion]);

    // useEffect(() => {
    //     if (window.location.hash === "#food-menu") {
    //         handleScrollToMenu()
    //     }
    //     if (window.location.hash === "#subscribe") {
    //         handleScrollToSubscribe()
    //     }
    // }, [])
    return (
        <div className='site-navigation-after-scroll'>
            {
                mobileVersion && <div className='site-navigation-after-scroll__burger-close' onClick={() => {
                    $('.desktop-burger').css({opacity: 1, display: 'block'});
                    $('.desktop-burger-close').css({opacity: 0, display: 'none'});
                    $('.site-navigation-after-scroll').css({opacity: 0});
                    setTimeout(() => {
                        $('.site-navigation-after-scroll').css({display: 'none'})
                    }, 500);
                }}><img src={burger}/></div>
            }
            <div className='header__navigation'
                 style={{flexDirection: 'column', height: 163, marginLeft: 0, marginBottom: 60}}>
                <a onClick={handleScrollToMenu}>{!props.isEnglish ? 'Меню' : 'Menu'}</a>
                <a href='/philosophy'>{!props.isEnglish ? 'Философия' : 'Philosophy'}</a>
                <a href='/spiroblog'>Spiro LIFE</a>
            </div>
            <div onClick={handleScrollToSubscribe} className='header__button-subscribe' style={{marginLeft: 0}}>
                {!props.isEnglish ? 'Подписаться' : 'Subscribe'}
            </div>
        </div>
    )
}
