import React, {useEffect} from 'react';
import $ from 'jquery';

export default function WatchVideo(props) {
    const {enableMoveLeft, disableMoveLeft, getCanScroll, isEnglish} = props;

    let isOpen;

    useEffect(() => {
        const watchVideo = $('.watch-video');
        const watchVideoPopup = $('.watch-video-popup');
        const videoElement = $('.watch-video-popup video');

        watchVideo.click(function () {
            videoElement.get(0).play();
            if (isOpen) return;
            watchVideoPopup.css({display: 'flex', 'justify-content': 'center', 'align-items': 'center'});
            $('header, .first-screen__title, .first-screen__text, #dish').css({filter: 'blur(10px)', transition: 'filter 1s'});
            videoElement.animate({maxWidth: '100%', maxHeight: '100%', opacity: 1}, 1000);
            setTimeout(() => {
                isOpen = true;
            }, 1000);
            disableMoveLeft();
            $('html, body').animate({scrollTop: 0});
            $('body').css({overflowY: 'hidden'});
        })

        $('body').click(() => {
            if (isOpen) {
                videoElement.get(0).pause();
                watchVideoPopup.hide();
                $('header, .first-screen__title, .first-screen__text, #dish').css({filter: 'blur(0)'});
                isOpen = false;
                enableMoveLeft();
                getCanScroll() && $('body').css({overflowY: 'scroll'});
            }
        })

        videoElement.click(function(e) {
            e.stopPropagation();
        });
    }, [])

    return (
        <div className='watch-video'>
            <div className='watch-video__button-play'>
                <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.46296 4.67409L2.50744 0.473742C1.64252 -0.13627 0.359949 0.937862 0.634137 1.96012C1.26691 4.31929 1.10674 6.20938 0.634602 7.74254C0.334603 8.71671 1.38219 9.84002 2.29502 9.38643L8.3316 6.38682C9.00979 6.04982 9.08183 5.11056 8.46296 4.67409Z"/>
                </svg>
            </div>
            <span>{!isEnglish ? 'Смотреть видео' : 'Watch video'}</span>
        </div>
    )
}