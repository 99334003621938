import React from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {useMediaQuery} from "react-responsive";

const addressConfig = {
    className: 'subscribe-form__town',
    options: ['Москва'],
    placeholder: 'Москва'
}

export default function Address() {
    const subscribeFormMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });
    return (
        <div className='subscribe-form__line' style={subscribeFormMobile ? {height: 245, opacity: 1} : {height: 162, opacity: 1}}>
            {
                !subscribeFormMobile
                    ? <h6 className='subscribe-form__title' style={{paddingBottom: 75}}>Адрес<br/>доставки</h6>
                    : <h6 className='subscribe-form__title' style={{top: 225, display: 'block'}}>Адрес доставки</h6>
            }
            <section className='subscribe-form__address'>
                <Dropdown {...addressConfig}/>
                {/*<input className='subscribe-form__town' placeholder='Москва' type='text'/>*/}
                <input className='subscribe-form__street' placeholder='Улица' type='text'/>
                <input className='subscribe-form__home' placeholder='Дом' type='text'/>
                <input className='subscribe-form__flat' placeholder={!subscribeFormMobile ? 'Кв' : 'Квартира'} type='text'/>
            </section>
        </div>
    )
}