import React from 'react';
import {useMediaQuery} from "react-responsive";

export default function YourComments() {
    const subscribeFormMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });
    return (
        <div className='subscribe-form__line'
             style={!subscribeFormMobile
                 ? {height: 133, opacity: 1, borderBottom: 'none'}
                 : {height: 177, opacity: 1}}>
            {
                !subscribeFormMobile
                    ? <h6 className='subscribe-form__title' style={
                        {paddingBottom: 0, marginTop: 0}
                    }>Ваш<br/>комментарий</h6>
                    : <h6 className='subscribe-form__title' style={{top: 620, display: 'block'}}>Ваш комментарий</h6>
            }
            <textarea className='subscribe-form__your-comments' placeholder='Здесь вы можете написать более
            точный адрес доставки, сведения
            об&nbsp;аллергенах&nbsp;и&nbsp;т.&nbsp;д.'/>
        </div>
    )
}