import React from 'react';
import $ from "jquery";
import resetPositions from "../resetPositions";

export default function NextWeek(props) {

    const changeActivity = () => {
        $('.food-menu__next-week .food-menu__user-choice').css({opacity: 1});
        $('.food-menu__current-week .food-menu__user-choice').css({opacity: 0});
        $('.food-menu__next-week span').css({fontWeight: 'bold'});
        $('.food-menu__current-week span').css({fontWeight: 'normal'});

        props.setWeek && props.setWeek(2);

        resetPositions();
        //
        // $('.food-menu__dish *').css({opacity: 0});
        // setTimeout(() => {
        //     setTimeout(() => $('.food-menu__dish *').css({opacity: 1}), 200);
        // }, 0);
    }

    return (
        <div className='food-menu__next-week' onClick={changeActivity}>
            <div style={{opacity: 0}} className='food-menu__user-choice'/>
            <span style={{fontWeight: 'normal'}}>{!props.isEnglish ? 'Следующая неделя' : 'Next week'}</span>
        </div>
    )
}
