import React, {useMemo} from 'react';
import FoodSet from "../foodSet";
import clsx from "clsx";

export default function FoodMenuDishes(props) {
    const {
        breakfast = [],
        dinner = [],
        supper = [],
        dishes,
        currentMealType = 'breakfast',
        currentDishIdx = 0,
        isEnglish
    } = props;

    const title = useMemo(
        () => {
            if (dishes[currentMealType] && dishes[currentMealType][currentDishIdx]) {
                return dishes[currentMealType][currentDishIdx].name
            } else if (dishes[currentMealType][0]) {
                return dishes[currentMealType][0].name
            } else {
                return ''
            }
        },
        [dishes, currentMealType, currentDishIdx]
    )

    const description = useMemo(
        () => {
            if (dishes[currentMealType] && dishes[currentMealType][currentDishIdx]) {
                return dishes[currentMealType][currentDishIdx].description
            } else if (dishes[currentMealType][0]) {
                return dishes[currentMealType][0].description
            } else {
                return ''
            }
        },
        [dishes, currentMealType, currentDishIdx]
    )

    const defaultBreakfastPhoto = useMemo(() => {
        if (breakfast[0]) {
            return breakfast[0].photo
        } else {
            return null
        }
    }, [breakfast])
    const defaultDinnerPhoto = useMemo(() => {
        if (dinner[0]) {
            return dinner[0].photo
        } else {
            return null
        }
    }, [dinner])
    const defaultSupperPhoto = useMemo(() => {
        if (supper[0]) {
            return supper[0].photo
        } else {
            return null
        }
    }, [supper])

    let dishesCount = -1;

    const dishClasses = [
        'food-menu__dish-current',
        'food-menu__dish-next',
        'food-menu__dish-copy'
    ]

    const dishLangName = !isEnglish ? 'name' : 'en_name';
    const dishLangDescription = !isEnglish ? 'description' : 'en_description';

    return <div className='food-menu__dishes'>
        {
            dishes[currentMealType].map((el, i) => {
                dishesCount++;
                const className = dishClasses[dishesCount];
                return (
                    <FoodSet
                        image={(el && el.photo)}
                        description={
                            <>{dishes[currentMealType][i][dishLangName]}
                            {(dishes[currentMealType][i][dishLangDescription] || '').length > 42
                                ? ' '
                                : <br/>}{dishes[currentMealType][i][dishLangDescription]}
                            </>}
                        className={`food-menu__dish ${className}`}
                    />
                )
            })
        }
        {/*<FoodSet*/}
        {/*    firstImage={(breakfast[0] && breakfast[0].photo)}*/}
        {/*    secondImage={(dinner[0] && dinner[0].photo)}*/}
        {/*    thirdImage={(supper[0] && supper[0].photo)}*/}
        {/*    description={<>{title}{(description || '').length > 42 ? ' ' : <br/>}{description}</>}*/}
        {/*    className={clsx({*/}
        {/*        'food-menu__dish': true,*/}
        {/*        'food-menu__dish-current': true*/}
        {/*    })}*/}
        {/*/>*/}
        {/*<FoodSet*/}
        {/*    firstImage={(breakfast[1] ? breakfast[1].photo : defaultBreakfastPhoto)}*/}
        {/*    secondImage={(dinner[1] ? dinner[1].photo : defaultDinnerPhoto)}*/}
        {/*    thirdImage={(supper[1] ? supper[1].photo : defaultSupperPhoto)}*/}
        {/*    description={<>{title}{(description || '').length > 42 ? ' ' : <br/>}{description}</>}*/}
        {/*    className={clsx({*/}
        {/*        'food-menu__dish': true,*/}
        {/*        'food-menu__dish-next': true,*/}
        {/*    })}*/}
        {/*/>*/}
        {/*<FoodSet*/}
        {/*    firstImage={(breakfast[0] && breakfast[0].photo)}*/}
        {/*    secondImage={(dinner[0] && dinner[0].photo)}*/}
        {/*    thirdImage={(supper[0] && supper[0].photo)}*/}
        {/*    description={<>{title}{(description || '').length > 42 ? ' ' : <br/>}{description}</>}*/}
        {/*    className={clsx({*/}
        {/*        'food-menu__dish': true,*/}
        {/*        'food-menu__dish-copy': true,*/}
        {/*    })}*/}
        {/*/>*/}
    </div>
}
