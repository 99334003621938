import React, {Fragment} from 'react';
import $ from 'jquery';
import Slider from 'react-slick';

import backgroundImage1 from '../../../public/our-team-carousel-background-image1.png';
import buttonLeft from '../../../public/ourTeam/button-left.svg';
import buttonRight from '../../../public/ourTeam/button-right.svg';
import {useMediaQuery} from "react-responsive";

let ourTeamMobile;


let disableClick = false;

function NextArrow(props) {
    if (ourTeamMobile) return <div/>
    const { className, style, onClick } = props;
    return (
        <div
            className='our-team__button-right'
            onClick={(event) => {
                if (disableClick) return;
                disableClick = true;
                $('.our-team__member img').animate({marginLeft: 50}, 500);
                setTimeout(() => $('.our-team__member img').animate({marginLeft: 0}, 1000), 500);
                onClick(event);
                setTimeout(() => {
                    disableClick = false;
                }, 1500)
            }}
        >
            <img src={buttonRight}/>
        </div>
    );
}

function PrevArrow(props) {
    if (ourTeamMobile) return <div/>
    const { className, style, onClick } = props;
    return (
        <div
            className='our-team__button-left'
            onClick={(event) => {
                if (disableClick) return;
                disableClick = true;
                $('.our-team__member img').animate({marginLeft: -50}, 500);
                setTimeout(() => $('.our-team__member img').animate({marginLeft: 0}, 1000), 500);
                onClick(event);
                setTimeout(() => {
                    disableClick = false;
                }, 1500)
            }}
        >
            <img src={buttonLeft}/>
        </div>
    );
}

export default function OurTeam(props) {
    const {apiData} = props;
    ourTeamMobile = useMediaQuery({
        query: '(max-width: 1024px)'
    })
    return (
        <section className='our-team-container'>
            <article className='our-team'>
                <div className='our-team__background'/>
                <h5 className='our-team__text'>
                    {!apiData.isEnglish ? 'Наша команда' : 'Our team'}
                </h5>
                <Slider className='our-team-carousel'
                        dots={true}
                        slidesToShow={2}
                        slidesToScroll={1}
                        speed={1500}
                        infinite={true}
                        nextArrow={<NextArrow/>}
                        prevArrow={<PrevArrow/>}
                        customPaging={(i) => {
                            return (
                                <a>
                                    <div className='dot-our-team'/>
                                </a>
                            )
                        }}
                        dotsClass={'slick-dots slick-dots-our-team'}
                        responsive={[{
                            breakpoint: 1150,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                infinite: false
                            }
                        },
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 1,
                                    infinite: true
                                }
                            }]}
                >
                    {
                        apiData.our_team.map(el => {
                            const member = el.split('///');
                            return (
                                <div className='our-team__member'>
                                    <img src={`/images/${member[3]}`}/>
                                    <div className='our-team__about-member'>
                                        <h6 dangerouslySetInnerHTML={{__html: member[0]}}></h6>
                                        <p dangerouslySetInnerHTML={{__html: member[2]}}></p>
                                    </div>
                                    <img className='our-team__background-image' src={backgroundImage1}/>
                                </div>
                            )
                        })
                    }
                </Slider>
            </article>
        </section>
    )
}
