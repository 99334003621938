import * as React from 'react'
import $ from "jquery";
// import {CSSTransition} from 'react-transition-group'
import "./subscribe-box.css"
// import YourName from "./yourName";
// import PhoneNumber from "./phoneNumber";
import Modal from "../modal";
// import {useMediaQuery} from "react-responsive";
import MaskedInput from "react-text-mask";
import {useEffect, useRef, useCallback} from "react";

function useOutsideAlerter(ref, onClickOutside) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onClickOutside]);
}

const SubscribeBox = (props) => {
    const { show, onClose, onSubmit, isEnglish } = props
    // const subscribeFormMobile = useMediaQuery({
    //     query: '(max-width: 768px)'
    // });
    const formRef = useRef(null)
    useOutsideAlerter(formRef, onClose)

    return <Modal show={show} onBackdropPress={onClose}>
        <div className='subscribe-form-modal' ref={formRef}>
            <h1>{!isEnglish ? 'Оформление предзаказа' : 'Preorder'}</h1>
            <p>
                {
                    !isEnglish
                        ? 'Спасибо за ваше доверие! Оставьте свои данные в форме ниже и мы запишем вас в список первых клиентов. Вы первыми узнаете о старте программы и получите бонусы за предзаказ.'
                        : 'Thank you for trusting us! Please leave your data in the form below and we\'ll enroll you in the list of first clients. You will know about the start from the stable and will get bonuses for preorder.'
                }
            </p>
            <div className="subscribe-form-div">
                <input onFocus={() => $('html, body, #app').css({overflowY: 'hidden'})} className='subscribe-form__your-name' placeholder={!isEnglish ? 'Ваше имя' : 'Your name'} type='text'/>
            </div>
            <div className='subscribe-form-div subscribe-form__phone-number-block'>
                <MaskedInput onFocus={() => $('html, body, #app').css({overflowY: 'hidden'})} className='subscribe-form__phone-number' placeholder={!isEnglish ? 'Телефон' : 'Phone'}
                             mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]} guide={true}
                />
            </div>
            <div className="subscribe-form-div">
                <input onFocus={() => $('html, body, #app').css({overflowY: 'hidden'})} className='subscribe-form__your-name' id="subscribe-form-email" placeholder='Email' type='text'/>
            </div>
            <button onClick={onSubmit}>{!isEnglish ? 'Запишите меня' : 'Subscribe'}</button>
        </div>
    </Modal>
}

export default SubscribeBox
