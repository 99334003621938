import React, {Fragment} from 'react';
import {Parallax} from "react-scroll-parallax";

import image from '../../../public/healthInfo/health-info-first.png';

let isSafari;

var ua = navigator.userAgent.toLowerCase();

export default function First(props) {

    const {apiData} = props;

    if (ua.indexOf('safari') !== -1) {
        if (ua.indexOf('chrome') > -1) {

        } else {
            isSafari = true;
        }
    }

    return (
        <div className='health-info__first health-info__block'>
            {
                !isSafari
                    ? <Parallax tagOuter={'figure'} y={[20, -20]}>
                        <div className='health-info__circle'>
                            <Parallax tagOuter={'figure'} y={[20, -20]}>
                                <h6 className='health-info__title health-info__title-first' dangerouslySetInnerHTML={{__html: apiData.health_info_first_main}}></h6>
                                <p className='health-info__description health-info__description-first' dangerouslySetInnerHTML={{__html: apiData.health_info_first_under_main}}>
                                </p>
                            </Parallax>
                        </div>
                    </Parallax>
                    : <Fragment>
                        <div className='health-info__circle'>
                            <Fragment>
                                <h6 style={{marginTop: 0}} className='health-info__title health-info__title-first' dangerouslySetInnerHTML={{__html: apiData.health_info_first_main}}></h6>
                                <p className='health-info__description health-info__description-first' dangerouslySetInnerHTML={{__html: apiData.health_info_first_under_main}}>
                                </p>
                            </Fragment>
                        </div>
                    </Fragment>
            }
            {
                isSafari ? <Parallax y={[0, 20]}>
                    <img className='health-info__image-first health-info__image' data-image={image}/>
                </Parallax> : <img className='health-info__image-first health-info__image' data-image={image}/>
            }
        </div>
    )
}