import React, {Fragment, useEffect} from 'react';
import $ from 'jquery';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {useMediaQuery} from "react-responsive";

// const setDateConfig = {
//     className: 'subscribe-form__set-date',
//     options: [ "28 февраля, Понедельник", '14 марта, Понедельник', '21 марта, Понедельник'],
//     placeholder: '28 февраля, Понедельник'
// }

export default function StartSubscribe(props) {
    const {apiData, onChange = () => null, value} = props;
    const mobileVersion = useMediaQuery({
        query: '(max-width: 768px)'
    });

    const setDateConfig = {
        className: 'subscribe-form__set-date',
        options: apiData.apiData.subscribe_form_date.split(';'),
        placeholder: apiData.apiData.subscribe_form_date.split(';')[0]
    }

    useEffect(() => {
        const firstOption = document.getElementsByTagName('.subscribe-form__set-date .Dropdown-option');
       // $('.subscribe-form__set-date .Dropdown-option').get(0).addClass('is-selected');
    }, [])
    return (
        <div className='subscribe-form__line' style={!mobileVersion ? {height: 91, borderBottom: 'none'} : {height: 110, paddingBottom: 10}}>
            <h6 className='subscribe-form__title' style={
                !mobileVersion ? {width: 100} : {top: 550}
            }>{
                !mobileVersion ? <Fragment>Дата старта<br/>подписки</Fragment> : <Fragment>Дата старта подписки</Fragment>
            }</h6>
            <Dropdown {...setDateConfig} onChange={onChange} value={value} />
        </div>
    )
}
