import {useState, useEffect, useCallback, useMemo} from 'react'
import {getMeals} from "../../libs/api";
import {withDefaultMealTypes} from "../../libs/utils";

export const useDishes = () => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [meals, setMeals] = useState({})
    const [todayDishes, setTodayDishes] = useState(withDefaultMealTypes({}))
    const [week, setWeek] = useState(1)
    const [dayOfWeek, setDayOfWeek] = useState(1)

    const loadDishes = useCallback(async () => {
        setMeals(await getMeals())
    }, [setMeals])

    useEffect(() => {
        (async function () {
            await loadDishes()
            setIsLoaded(true)
        })()
    }, [])

    useEffect(() => {
        const dishes = withDefaultMealTypes(meals[`${week}_${dayOfWeek}`])
        setTodayDishes(dishes)
    }, [meals, week, dayOfWeek])

    return useMemo(() => ([
        isLoaded ? todayDishes : null,
        [].concat(todayDishes.breakfast).concat(todayDishes.dinner).concat(todayDishes.supper),
        setWeek,
        setDayOfWeek,
        loadDishes
    ]), [isLoaded, todayDishes, setWeek, setDayOfWeek, loadDishes])
}
