import React from 'react';

export default function FoodSet(props) {
    const {image, description, className} = props;

    return (
        <div className={className}>
            <div className='food-menu__first-image'>
                <img data-image={`/images/${image}`} src={`/images/${image}`}/>
            </div>
            <p className='food-menu__dish-description'>{description}</p>
        </div>
    )
}
