import * as THREE from 'three';
import $ from 'jquery';
import SmoothScroll from '../../libs/scrollSmooth';

let disablePackAnimation;

let mesh;

let sunLight, leftDirectionalLight, rightDirectionalLight;

const scrollDown = () => {
    if (!disablePackAnimation) {
        // if (width > 1024) {
        //     $('html, body').animate({scrollTop: $('.food-as-medicine__main-text').offset().top - 40}, 100);
        // } else {
        //     $('html, body').animate({scrollTop: $('.food-as-medicine__main-text').offset().top - 200}, 100);
        // }
        $('html, body').animate({scrollTop: $('.food-as-medicine__main-text').offset().top - 40}, 100);
        $('#dish').animate({top: -500, opacity: 0}, 800);
        $('.spiro-blick').css({display: 'block'})
        $('.spiro-blick').css({opacity: 1}, 500);
        $('.spiro-blick').animate({left: '100vw'}, 1200, 'linear');
    }
    setTimeout(() => $('.food-as-medicine_little-text').animate({opacity: 1}, 1500), 500);
    $('.blue-ellipse').addClass('blue-ellipse_active');
    setTimeout(() => {
        SmoothScroll({ stepSize: 100 });
        const width = window.innerWidth;
        if (width <= 1024) {
            $('.dish-with-dessert-static').css({opacity: 1});
        }
        $('.blue-ellipse').css({zIndex: 0})
        $('#dish').css({position: 'relative', top: -400, left: 0, opacity: 1});
        leftDirectionalLight.position.set(-1, 0, 0);
        leftDirectionalLight.intensity = .9;
        mesh.rotation.x = .85;
        mesh.rotation.y = -2.705;
        mesh.rotation.z = 0.25;
        $('.dish__shadow, .spiro-background').css({opacity: 1});
        $('header').css({top: 0, opacity: 1});
        $('.first-screen__title').css({top: 0, opacity: 1});
        $('.first-screen__text').css({top: 0, opacity: 1});
        $('.watch-video').css({top: 0, opacity: 1});
        $('body').css({overflowY: 'scroll'});
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {

            } else {
                $('html, body').css({'overflow-y': 'scroll'});
            }
        }
        document.getElementsByClassName('dish__flying-dish')[0].remove();
        $('.food-as-medicine__dish-with-dessert').css({opacity: 1});
        $('.food-as-medicine__text-under-main-text').animate({opacity: 1}, 1000);
        $('.food-as-medicine__main-text').animate({opacity: 1}, 1000);
        setTimeout(() => {
            $('.health-info__first img').attr('src', $('.health-info__first img').data('image'));
            $('.health-info__second img').attr('src', $('.health-info__second img').data('image'));
            $('.health-info__third img').attr('src', $('.health-info__third img').data('image'));
            $('.health-info__fourth img').attr('src', $('.health-info__fourth img').data('image'));
        })
    }, 1200);
}

let rotateDish;
let canRotate;
let canMoveLeft;
let canScroll;

export default function RenderPack(values, getCanMoveLeft, setCanScroll, getDisablePackAnimation, setPackRequestId) {
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    const camera = new THREE.PerspectiveCamera(15, 1, 1, 2000);
    const scene = new THREE.Scene();

    const moveLeft = (event) => {
        if (disablePackAnimation) {
            window.removeEventListener('wheel', moveLeft);
            scrollDown();
            return;
        }
        if (event.deltaY > 0 && canMoveLeft()) {
            canRotate = true;
            canScroll(true);
            window.removeEventListener('wheel', moveLeft);
        }
    }

    const moveLeftTouch = event => {
        if (disablePackAnimation) {
            window.removeEventListener('touchmove', moveLeftTouch);
            scrollDown();
            return;
        }
        canRotate = true;
        // $('#dish').animate({top: -600}, 500)
        canScroll(true);
        window.removeEventListener('touchmove', moveLeftTouch);
    }

    canMoveLeft = getCanMoveLeft;
    canScroll = setCanScroll;

    renderer.setSize(600, 600)

    renderer.setClearColor(0x000000, 0);
    renderer.setPixelRatio(window.devicePixelRatio);
    document.getElementById('dish').appendChild(renderer.domElement);

    camera.position.set(0,0, 1500);

    const sunLightIntensity = 1.939;

    sunLight = new THREE.DirectionalLight(0xfbf7ff, sunLightIntensity);
    leftDirectionalLight = new THREE.DirectionalLight(0xffffff, .9);
    rightDirectionalLight = new THREE.DirectionalLight(0xf9fbfd, .6);

    sunLight.position.set(30, 15, 0);
    leftDirectionalLight.position.set(-1, 0, 0);
    rightDirectionalLight.position.set(1, -7, 0);

    scene.add(sunLight);
    scene.add(leftDirectionalLight);
    scene.add(rightDirectionalLight);

    const texture = new THREE.Texture();

    const [image, obj] = values;

    mesh = obj;

    texture.image = image;
    texture.needsUpdate = true;

    obj.traverse(child => {
        if (child instanceof THREE.Mesh) {
            child.material = new THREE.MeshLambertMaterial({
                map: texture
            })
        }
    })

    obj.rotation.x += .85;
    obj.rotation.y += -2.705;
    obj.rotation.z += 0.25

    scene.add(obj);

    const spiroBackground = $('.spiro-background');
    const dishShadow = $('.dish__shadow');
    const dish = $('#dish');
    const header = $('header');
    const firstScreenTitle = $('.first-screen__title');
    const firstScreenText = $('.first-screen__text');
    const watchVideo = $('.watch-video');
    const flyingDish = $('.dish__flying-dish');
    const dishDessert = $('.dish__dessert');

    let firstScreenTop = 0;
    let firstScreenOpacity = 1;

    let onlyOnce;
    function rendering() {
        const requestId = requestAnimationFrame(rendering);
        setPackRequestId(requestId);
        renderer.render(scene, camera);

        disablePackAnimation = getDisablePackAnimation();

        if (canRotate) {
            const dishLeft = dish.css('left').split('px')[0];
            if (!onlyOnce) {
                spiroBackground.css({opacity: 0});
                dishShadow.css({opacity: 0});
                onlyOnce = true;
            }
            firstScreenOpacity -= 0.025;
            header.css({opacity: firstScreenOpacity});
            firstScreenTitle.css({opacity: firstScreenOpacity});
            firstScreenText.css({opacity: firstScreenOpacity});
            watchVideo.css({opacity: firstScreenOpacity});
            if (dishLeft > -252.5) {
                dish.css({left: dishLeft - 7});
                for (let i = 0; i < 10; i++) {
                    firstScreenTop -= 1;
                    header.css({top: firstScreenTop});
                    firstScreenTitle.css({top: firstScreenTop});
                    firstScreenText.css({top: firstScreenTop});
                    watchVideo.css({top: firstScreenTop});
                }
            }
            if (obj.rotation.x < 1.45) {
                obj.rotation.x += 0.03;
                leftDirectionalLight.position.x -= 0.0255;
                leftDirectionalLight.position.z += 0.0255;
                leftDirectionalLight.intensity += 0.0525;
            }
            if (obj.rotation.y < -1.6) {
                obj.rotation.y += 0.03;
            }
            if (obj.rotation.x >= 1.45 && obj.rotation.y >= -1.6 && dishLeft <= -252.5) {
                canRotate = false;
                dish.css({position: 'fixed', left: dish.position().left, top: dish.position().top});
                scrollDown();
                flyingDish.css({display: 'flex', position: 'fixed', top: dish.position().top});
                flyingDish.animate({opacity: 1, top: 135}, 416.25);
                setTimeout(() => {
                    $('.dish__table-dish').animate({opacity: 1}, 666.25);
                    dishDessert.animate({opacity: 0}, 832.5);
                    flyingDish.animate({top: flyingDish.position().top + 118}, 600)
                }, 400);
            }
        }
    }

    rendering();

    setTimeout(() => {
        spiroBackground.animate({opacity: 1}, 500);
        dishShadow.animate({opacity: 1}, 500);
    }, 1000);

    let rotationItem;
    const browser = navigator.userAgent.toLowerCase();
    if (browser.indexOf('safari') !== -1) {
        if (browser.indexOf('chrome') > -1) {
            rotationItem = .5;
        } else {
            rotationItem = .25;
        }
    }

    let x = 0;
    rotateDish = (event) => {
        const spiroBackgroundMarginLeft = Number(spiroBackground.css('margin-left').split('px')[0]);
        if (event.clientX < x && obj) {
            obj.rotation.y += 0.001;
            spiroBackground.css({'margin-left': spiroBackgroundMarginLeft + rotationItem});
        }
        if (event.clientX > x && obj) {
            obj.rotation.y -= 0.001;
            spiroBackground.css({'margin-left': spiroBackgroundMarginLeft - rotationItem});
        }
        x = event.clientX;
    }

    window.addEventListener('mousemove', rotateDish);
    setTimeout(() => {
        window.addEventListener('wheel', moveLeft);
        window.addEventListener('touchmove', moveLeftTouch)
    }, 2000);

}
