import React, {Fragment, useEffect} from 'react';
import $ from "jquery";
import {useMediaQuery} from "react-responsive";

export default function Days(props) {
    const {mark, isEnglish} = props;

    const mobileVersion = useMediaQuery({
        query: '(max-width: 992px)'
    });

    useEffect(() => {
        const fiveDays = $('.subscribe-form__5-days');
        const threeDays = $('.subscribe-form__3-days');

        let isClicked;

        const fiveDaysCircle = fiveDays.find('.subscribe-form__circle');
        const threeDaysCircle = threeDays.find('.subscribe-form__circle');
        fiveDays.click(() => {
            if (isClicked) return;
            isClicked = true;
            setTimeout(() => isClicked = false, 400);
            if (fiveDaysCircle.css('background-color') !== 'rgba(0, 0, 0, 0)') {
                fiveDaysCircle.css( {background: '#146957', border: '1px solid #146957'} );
                threeDaysCircle.css( {background: '#fff', border: '1px solid #1d1d1d'} );
            }
            $('.subscribe-form__3-days .subscribe-form__checked-mark').css({opacity: 0});
            $('.subscribe-form__5-days .subscribe-form__checked-mark').css({opacity: 1, width: 28, height: 20});
            $('.subscribe-form__5-days .subscribe-form__checked-mark').animate({opacity: 1, width: 14, height: 10}, 100);
            $('.subscribe-form__input-five').prop('checked', true);
        })
        threeDays.click(() => {
            if (isClicked) return;
            isClicked = true;
            setTimeout(() => isClicked = false, 400);
            if (threeDaysCircle.css('background-color') !== 'rgba(0, 0, 0, 0)') {
                threeDaysCircle.css( {background: '#146957', border: '1px solid #146957'} );
                fiveDaysCircle.css( {background: '#fff', border: '1px solid #1d1d1d'} );
            }
            $('.subscribe-form__5-days .subscribe-form__checked-mark').css({opacity: 0});
            $('.subscribe-form__3-days .subscribe-form__checked-mark').css({opacity: 1, width: 28, height: 20});
            $('.subscribe-form__3-days .subscribe-form__checked-mark').animate({opacity: 1, width: 14, height: 10}, 100);
            $('.subscribe-form__input-three').prop('checked', true);
        })
    }, [])

    return (
        <div className='subscribe-form__line' style={
            !mobileVersion ? {height: 91} : {height: 71, borderBottom: 'none'}
        }>
            <h6 className='subscribe-form__title' style={mobileVersion ? {top: 120} : {}}>{
                !mobileVersion
                    ? !isEnglish ? <Fragment>Дней в<br/>неделю</Fragment> : <Fragment>Days per<br/>week</Fragment>
                    : !isEnglish ? <Fragment>Дней в неделю</Fragment> : <Fragment>Days per week</Fragment>
            }</h6>
            <label className='subscribe-form__label subscribe-form__5-days' style={
                !mobileVersion
                    ? {height: 27, marginLeft: 15}
                    : {height: 20.38, marginLeft: 0, marginTop: 40}
            }>
                <div className='subscribe-form__circle'>
                    <img className='subscribe-form__checked-mark' src={mark}/>
                </div>
                <span style={!mobileVersion ? {marginLeft: 10} : {marginLeft: 5}}>5 {!isEnglish ? 'дней' : 'days'}</span>
                <input className='subscribe-form__input-five' type='radio' name='days' value='five'/>
            </label>
            <label className='subscribe-form__label subscribe-form__3-days' style={
                !mobileVersion
                    ? {height: 27, marginLeft: 34}
                    : {height: 20, marginLeft: '7%', marginTop: 40}
            }>
                <div className='subscribe-form__circle'>
                    <img className='subscribe-form__checked-mark' src={mark}/>
                </div>
                <span style={!mobileVersion ? {marginLeft: 10} : {marginLeft: 5}}>3 {!isEnglish ? 'дня' : 'days'}</span>
                <input className='subscribe-form__input-three' type='radio' name='days' value='three'/>
            </label>
        </div>
    )
}