import React from 'react';
import {useMediaQuery} from "react-responsive";

export default function Element(props) {
    const {number, image, text} = props;
    const mobileVersion = useMediaQuery({
        query: '(max-width: 768px)'
    });
    return (
        !mobileVersion ? <div className={`superset__element ${number}`}>
            <img className='superset__image' src={image}/>
            <p className='superset__element-description' dangerouslySetInnerHTML={{__html: text}}>
            </p>
        </div> : <div className={`superset__element`}>
            <img className='superset__image' src={image}/>
            <p className='superset__element-description' dangerouslySetInnerHTML={{__html: text}}>
            </p>
        </div>
    )
}