import React from "react";
import $ from 'jquery';
import resetPositions from "../resetPositions";

export default function CurrentWeek(props) {
    const changeActivity = () => {
        $('.food-menu__current-week .food-menu__user-choice').css({opacity: 1});
        $('.food-menu__next-week .food-menu__user-choice').css({opacity: 0});
        $('.food-menu__current-week span').css({fontWeight: 'bold'});
        $('.food-menu__next-week span').css({fontWeight: 'normal'});

        props.setWeek && props.setWeek(1);

        resetPositions();

        // $('.food-menu__dish *').css({opacity: 0});
        // setTimeout(() => {
        //     setTimeout(() => $('.food-menu__dish *').css({opacity: 1}), 10);
        // }, 0);
    }

    return (
        <div className='food-menu__current-week' onClick={changeActivity}>
            <div style={{opacity: 1}} className='food-menu__user-choice'/>
            <span style={{fontWeight: 'bold'}}>{!props.isEnglish ? 'Текущая неделя' : 'This week'}</span>
        </div>
    )
}
