import React, {Fragment, useEffect} from 'react';
import $ from "jquery";
import {useMediaQuery} from "react-responsive";

export default function Times(props) {
    const {mark, isEnglish} = props;

    const mobileVersion = useMediaQuery({
        query: '(max-width: 992px)'
    });

    useEffect(() => {
        const breakfast = $('.subscribe-form__breakfast');
        const lunch = $('.subscribe-form__lunch');
        const dinner = $('.subscribe-form__dinner');
        breakfast.css({background: '#146957', opacity: .5});
        lunch.css({background: '#146957', opacity: .5});
        dinner.css({background: '#146957', opacity: .5});
        $('.subscribe-form__breakfast .subscribe-form__checked-mark').css({opacity: 1});
        $('.subscribe-form__lunch .subscribe-form__checked-mark').css({opacity: 1});
        $('.subscribe-form__dinner .subscribe-form__checked-mark').css({opacity: 1});
    }, [])

    return (
        <div className='subscribe-form__line' style={!mobileVersion ? {height: 66} : {height: 78}}>
            <h6 className='subscribe-form__title' style={
                !mobileVersion ? {width: 109} : {top: 186}
            }>{
                !mobileVersion
                    ? !isEnglish ? <Fragment>Приёмы<br/>пищи</Fragment> : <Fragment>Meals</Fragment>
                    : !isEnglish ? <Fragment>Приёмы пищи</Fragment> : <Fragment>Meals</Fragment>
            }</h6>
            <label className='subscribe-form__label' style={
                !mobileVersion ? {height: 26, marginLeft: 15, cursor: 'unset', top: 0} : {height: 20, marginTop: 30, marginLeft: 0}
            }>
                <div className='subscribe-form__rectangle subscribe-form__breakfast'>
                    <img className='subscribe-form__checked-mark' src={mark}/>
                </div>
                <span style={!mobileVersion ? {marginLeft: 10} : {marginLeft: 5}}>{!isEnglish ? 'Завтрак' : 'Breakfast'}</span>
                <input type='checkbox' name='times' value='breakfast' defaultChecked/>
            </label>
            <label className='subscribe-form__label' style={
                !mobileVersion ? {height: 26, marginLeft: !isEnglish ? 25 : 18.5, cursor: 'unset', top: 0} : {height: 20, marginTop: 30, marginLeft: '7%'}
            }>
                <div className='subscribe-form__rectangle subscribe-form__lunch'>
                    <img className='subscribe-form__checked-mark' src={mark}/>
                </div>
                <span style={!mobileVersion ? {marginLeft: 10} : {marginLeft: 5}}>{!isEnglish ? 'Обед' : 'Lunch'}</span>
                <input type='checkbox' name='times' value='lunch' defaultChecked/>
            </label>
            <label className='subscribe-form__label' style={
                !mobileVersion ? { height: 26, marginLeft: !isEnglish ? 25 : 18.5, cursor: 'unset', top: 0} : {height: 20, marginTop: 30, marginLeft: '7%'}
            }>
                <div className='subscribe-form__rectangle subscribe-form__dinner'>
                    <img className='subscribe-form__checked-mark' src={mark}/>
                </div>
                <span style={!mobileVersion ? {marginLeft: 10} : {marginLeft: 5}}>{!isEnglish ? 'Ужин' : 'Dinner'}</span>
                <input type='checkbox' name='times' value='dinner' defaultChecked/>
            </label>
        </div>
    )
}