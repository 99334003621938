import * as THREE from "three";

import PackMaterial from '../../../public/models/pack/Material.jpg';
import PackObj from "../../../public/models/pack/pack.obj";
import PackMaterialEng from '../../../public/models/enPack/Package.png'
// import EnPackObj from '../../../public/models/enPack/EnPack.obj'
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import iphoneMtl from "../../../public/models/iphone/Iphone.mtl";
import {MtlObjBridge} from "three/examples/jsm/loaders/obj2/bridge/MtlObjBridge";
import iphoneObj from "../../../public/models/iphone/Iphone.obj";
import {OBJLoader2} from "three/examples/jsm/loaders/OBJLoader2";
import {MTLLoader} from "three/examples/jsm/loaders/MTLLoader";

import iphoneTexture1 from "../../../public/models/iphone/Iphone_Texture1.jpg";
import iphoneTexture2 from "../../../public/models/iphone/Iphone_Texture2.jpg";
import iphoneTexture3 from "../../../public/models/iphone/Iphone_Texture3.jpg";
import iphoneTexture4 from "../../../public/models/iphone/Iphone_Texture4.jpg";
import iphoneTexture5 from "../../../public/models/iphone/Iphone_Texture5.jpg";
import iphoneTexture6 from "../../../public/models/iphone/Iphone_Texture6.jpg";
import iphoneTextureEng1 from "../../../public/phones/Iphone_Texture1eng.jpg";
import iphoneTextureEng2 from "../../../public/phones/Iphone_Texture2eng.jpg";
import iphoneTextureEng3 from "../../../public/phones/Iphone_Texture3eng.jpg";
import iphoneTextureEng4 from "../../../public/phones/Iphone_Texture4eng.jpg";
import iphoneTextureEng5 from "../../../public/phones/Iphone_Texture5eng.jpg";
import iphoneTextureEng6 from "../../../public/phones/Iphone_Texture6eng.jpg";

const [width, height] = [window.innerWidth, window.innerHeight];

const manager = new THREE.LoadingManager();
const loader = new THREE.ImageLoader(manager);

const objLoader = new OBJLoader();
const mtlLoader = new MTLLoader();
const objLoader2 = new OBJLoader2();

const textureLoaderPromise = new Promise(resolve => {
    let material;

    switch (window.location.hostname) {
        case 'spiro.cooking':
            material = PackMaterialEng;
            break
        case 'spirocooking.com':
            material = PackMaterialEng;
            break
        default:
            material = PackMaterial;
            break
    }
        loader.load(material, image => resolve(image));
    });

const objectLoaderPromise = new Promise(resolve => {
    let obj;

    obj = PackObj;

        objLoader.load(obj, obj => resolve(obj));
    })

const getTexture = (rus, eng) => {
    if (window.location.hostname === 'spiro.cooking' || window.location.hostname === 'spirocooking.com') {
        return eng;
    }

    return rus;
}

const iphoneImage1 = new Promise(resolve => {
    if (width < 992) return;
    loader.load(getTexture(iphoneTexture1, iphoneTextureEng1), image => resolve(image));
})

const iphoneImage2 = new Promise(resolve => {
    if (width < 992) return;
    loader.load(getTexture(iphoneTexture2, iphoneTextureEng2), image => resolve(image));
})

const iphoneImage3 = new Promise(resolve => {
    if (width < 992) return;
    loader.load(getTexture(iphoneTexture3, iphoneTextureEng3), image => resolve(image));
})

const iphoneImage4 = new Promise(resolve => {
    if (width < 992) return;
    loader.load(getTexture(iphoneTexture4, iphoneTextureEng4), image => resolve(image));
})

const iphoneImage5 = new Promise(resolve => {
    if (width < 992) return;
    loader.load(getTexture(iphoneTexture5, iphoneTextureEng5), image => resolve(image));
})

const iphoneImage6 = new Promise(resolve => {
    if (width < 992) return;
    loader.load(getTexture(iphoneTexture6, iphoneTextureEng6), image => resolve(image));
})

const mtlIphone = new Promise(resolve => {
    if (width < 992) return;
    mtlLoader.load(iphoneMtl, result => resolve(result))
})

const objIphone = new Promise(resolve => {
    if (width < 992) return;
    mtlIphone.then(mtlResult => {
        const materials = MtlObjBridge.addMaterialsFromMtlLoader(mtlResult);
        objLoader2.addMaterials(materials);
        objLoader2.load(iphoneObj, result => resolve(result))
    })
})

export function LoaderPromise() {
    return Promise.all([
        textureLoaderPromise,
        objectLoaderPromise
    ])
}

export function LoaderPromisePhone() {
    if (width < 992) return;
    return Promise.all([
        iphoneImage1,
        iphoneImage2,
        iphoneImage3,
        iphoneImage4,
        iphoneImage5,
        iphoneImage6,
        objIphone
    ])
}