import React, {useState, useEffect, useRef} from 'react'
import ReactDOM from 'react-dom'
import "./index.css"
import {CSSTransition} from "react-transition-group";

const Modal = (props) => {
    const { onBackdropPress = () => null } = props
    const [el, setEl] = useState(null)
    const backdropRef = useRef(null)
    useEffect(() => {
        if (document.querySelector('modal-root')) {
            setEl(document.querySelector('modal-root'))
            return
        }
        const modalRootEl = document.createElement('div')
        document.body.append(modalRootEl)
        modalRootEl.id = "modal-root"
        setEl(modalRootEl)
        return () => {
            document.body.removeChild(document.querySelector("modal-root"))
        }
    }, [])

    if (!el) {
        return null
    }

    return ReactDOM.createPortal(
        <CSSTransition in={props.show} timeout={250} classNames="sub-modal-box">
            <div className="sub-backdrop">
                {props.children}
            </div>
        </CSSTransition>,
        el
    )
}

export default Modal
