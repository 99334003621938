import React, {Fragment, useEffect, useCallback, useMemo} from 'react';
import { useMediaQuery } from 'react-responsive';

import logo from '../../../public/logo.svg';
import logoAfterScroll from '../../../public/firstScreen/headerSymbol.png';
import burger from '../../../public/burger.svg';
import $ from "jquery";

let navigationIsOpen;

export default function Header(props) {
    const mediaHeader = useMediaQuery({
        query: '(max-width: 992px)'
    })

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if ($('html, body').scrollTop() > 2000) {
                $('.footer__up').css({opacity: 1});
            } else {
                $('.footer__up').css({opacity: 0});
            }
            if ($('html, body').scrollTop() > 300) {
                $('.logo-after-scroll, .desktop-burger').css({opacity: 1}, 1000);
            } else {
                $('.logo-after-scroll, .desktop-burger').css({opacity: 0}, 1000);
            }

        })
    }, [])

    const {setDisablePackAnimation, isEnglish} = props;
    const handleScrollToSubscribe = useCallback(() => {
        setDisablePackAnimation(true);
        $('html, body').animate({scrollTop: $('.subscribe-form').offset().top - 100}, 1000);
        $('body').css({overflowY: 'scroll'});
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {

            } else {
                $('html, body').css({'overflow-y': 'scroll'});
            }
        } else {
        }
    }, [])
    const handleScrollToFoodMenu = useCallback(() => {
        setDisablePackAnimation(true);
        const location = !mediaHeader ? $('.food-menu-container').offset().top : $('.food-menu-mobile-container').offset().top;
        $('html, body').animate({
            scrollTop: location
        }, 1000);
        $('body').css({overflowY: 'scroll'});
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {

            } else {
                $('html, body').css({'overflow-y': 'scroll'});
            }
        } else {
        }
    }, [])

    useEffect(() => {
        if (window.location.hash === "#subscribe") {
            handleScrollToSubscribe();
            mediaHeader && window.addEventListener('touchmove', () => {
                $('html, body').css({overflow: 'scroll'});
            })
        }
        if (window.location.hash === "#food-menu") {
            handleScrollToFoodMenu();
            mediaHeader && window.addEventListener('touchmove', () => {
                $('html, body').css({overflow: 'scroll'});
            })
        }
    }, [])

    return (
        <Fragment>
            {
                !mediaHeader ?
                    <Fragment>
                        <img className='logo-after-scroll' src={logoAfterScroll}/>
                        <svg onClick={() => {
                            $('html, body').css({overflow: 'hidden'});
                            $('.desktop-burger').css({opacity: 0, display: 'none'});
                            $('.desktop-burger-close').css({opacity: 1, display: 'block'});
                            $('.site-navigation-after-scroll').css({display: 'flex'});
                            setTimeout(() => {
                                $('.site-navigation-after-scroll').css({opacity: 1})
                            }, 10);
                        }} className='desktop-burger' width="37" height="24" viewBox="0 0 37 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="37" height="2" rx="1" fill="#1D1D1D"/>
                            <rect y="11" width="37" height="2" rx="1" fill="#1D1D1D"/>
                            <rect x="18" y="22" width="19" height="2" rx="1" fill="#1D1D1D"/>
                        </svg>
                        <svg onClick={() => {
                            $('html, body').css({overflow: 'scroll'});
                            $('.desktop-burger').css({opacity: 1, display: 'block'});
                            $('.desktop-burger-close').css({opacity: 0, display: 'none'});
                            $('.site-navigation-after-scroll').css({opacity: 0});
                            setTimeout(() => {
                                $('.site-navigation-after-scroll').css({display: 'none'})
                            }, 500);
                        }} className='desktop-burger-close' width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="2.47949" width="37" height="1.99715" rx="0.998574" transform="rotate(45 2.47949 0)" fill="#1D1D1D"/>
                            <rect x="0.35791" y="26.5205" width="37" height="2.00208" rx="1.00104" transform="rotate(-45 0.35791 26.5205)" fill="#1D1D1D"/>
                        </svg>
                        <header>
                            <img id='logo' src={logo}/>
                            <div className='header__navigation'>
                                <a onClick={() => {
                                    setDisablePackAnimation(true);
                                    $('html, body').animate({scrollTop: $('.food-menu-container').offset().top}, 1000);
                                    $('body').css({overflowY: 'scroll'});
                                    var ua = navigator.userAgent.toLowerCase();
                                    if (ua.indexOf('safari') !== -1) {
                                        if (ua.indexOf('chrome') > -1) {

                                        } else {
                                            $('html, body').css({'overflow-y': 'scroll'});
                                        }
                                    } else {
                                    }
                                }}>{!isEnglish ? 'Меню' : 'Menu'}</a>
                                <a href='./philosophy'>{!isEnglish ? 'Философия' : 'Philosophy'}</a>
                                <a href='./spiroblog'>Spiro LIFE</a>
                            </div>
                            <div className='header__button-subscribe' onClick={handleScrollToSubscribe}>{!isEnglish ? 'Подписаться' : 'Subscribe'}</div>
                        </header>
                    </Fragment>
                    :
                    <Fragment>
                        <header className='header-mobile'>
                            <img className='header__burger' src={burger} onClick={() => {
                                setDisablePackAnimation(true);
                                if (!navigationIsOpen) {
                                    navigationIsOpen = true;
                                    $('html, body').css({overflow: 'hidden'});
                                    $('.site-navigation-after-scroll').css({display: 'flex'});
                                    setTimeout(() => {
                                        $('.site-navigation-after-scroll').css({opacity: 1})
                                    }, 10);
                                } else {
                                    navigationIsOpen = false;
                                    $('html, body').css({overflow: 'scroll'});
                                    setTimeout(() => {
                                        $('.site-navigation-after-scroll').css({opacity: 0})
                                    }, 10);
                                    setTimeout(() => {
                                        $('.site-navigation-after-scroll').css({display: 'none'});
                                    }, 600)
                                }
                            }}/>
                            <img id='logo' src={logo}/>
                        </header>
                        <header className='header-mobile_fixed'>
                            <img className='header__burger' src={burger} onClick={() => {
                                if (!navigationIsOpen) {
                                    navigationIsOpen = true;
                                    $('html, body').css({overflow: 'hidden'});
                                    $('.site-navigation-after-scroll').css({display: 'flex'});
                                    setTimeout(() => {
                                        $('.site-navigation-after-scroll').css({opacity: 1})
                                    }, 10);
                                } else {
                                    navigationIsOpen = false;
                                    $('html, body').css({overflow: 'scroll'});
                                    setTimeout(() => {
                                        $('.site-navigation-after-scroll').css({opacity: 0})
                                    }, 10);
                                    setTimeout(() => {
                                        $('.site-navigation-after-scroll').css({display: 'none'});
                                    }, 600)
                                }
                            }}/>
                            <img id='logo' src={logo}/>
                        </header>
                    </Fragment>
            }
        </Fragment>
    )
}
