import React, {Fragment, useState, useCallback, useEffect} from 'react';
import $ from 'jquery';

import Sex from "./sex";
import checkedMark from "../../../public/checked-mark.svg";
import Days from "./days";
import Times from "./times";
import Superset from "./superset";
import ProgramType from "./programType";
import StartSubscribe from "./startSubscribe";
import {useMediaQuery} from "react-responsive";
import YourName from "./yourName";
import PhoneNumber from './phoneNumber';
import Address from './address';
import DeliveryTime from "./deliveryTime";
import YourComments from "./yourComments";
import {subscribePackage} from '../../libs/api'
import SubscribeBox from "./subscribe-box";
import ThanksForSubscribe from "../add-email/thanksForSubscribe";

const getFirstForm = () => {
    const sex = document.getElementsByClassName('subscribe-form__input-man')[0].checked ? 'Муж' : 'Жен';
    const freq = document.getElementsByClassName('subscribe-form__input-three').days.checked ? 3 : document.getElementsByClassName('subscribe-form__input-five').days.checked ? 5 : 0
    let type = null
    if (document.getElementsByClassName('subscribe-form__input-trial').programType.checked) {
        type = 'trial'
    } else if (document.getElementsByClassName('subscribe-form__input-every').programType.checked) {
        type = 'weekly'
    }
    let superSet = null
    if (document.getElementsByClassName('subscribe-form__input-beauty').superset.checked) {
        superSet = 'beauty'
    } else if (document.getElementsByClassName('subscribe-form__input-performance').superset.checked) {
        superSet = 'performance'
    }
    return {
        sex,
        freq,
        type,
        superSet
    }
}

const checkLines = () => {
    const inputSex = document.getElementsByClassName('subscribe-form__input-man')[0].checked ||
        document.getElementsByClassName('subscribe-form__input-woman')[0].checked ||
        document.getElementsByClassName('subscribe-form__input-man')[1].checked ||
        document.getElementsByClassName('subscribe-form__input-woman')[1].checked;
    const inputDays = document.getElementsByClassName('subscribe-form__input-three')[0].checked ||
        document.getElementsByClassName('subscribe-form__input-five')[0].checked ||
        document.getElementsByClassName('subscribe-form__input-three')[1].checked ||
        document.getElementsByClassName('subscribe-form__input-five')[1].checked;
    const inputType = document.getElementsByClassName('subscribe-form__input-every')[0].checked ||
        document.getElementsByClassName('subscribe-form__input-trial')[0].checked ||
        document.getElementsByClassName('subscribe-form__input-every')[1].checked ||
        document.getElementsByClassName('subscribe-form__input-trial')[1].checked;
    // const inputStyle = document.getElementsByClassName('subscribe-form__input-beauty')[0].checked ||
    //     document.getElementsByClassName('subscribe-form__input-performance')[0].checked ||
    //     document.getElementsByClassName('subscribe-form__input-beauty')[1].checked ||
    //     document.getElementsByClassName('subscribe-form__input-performance')[1].checked;
    const inputStyle = true;

    (!inputSex || !inputDays || !inputType || !inputStyle) && $('.subscribe-form__not-all-fields').css({opacity: 1, display: 'flex'})

    return inputSex && inputDays && inputType && inputStyle;
}

const getSecondForm = () => {
    const phoneNumber = document.getElementsByClassName('subscribe-form__phone-number')[0].value
    const name = document.getElementsByClassName('subscribe-form__your-name')[0].value
    // const street = document.getElementsByClassName('subscribe-form__street')[0].value
    // const home = document.getElementsByClassName('subscribe-form__home')[0].value
    // const flat = document.getElementsByClassName('subscribe-form__flat')[0].value;
    const email = document.getElementById('subscribe-form-email').value

    return {
        phoneNumber,
        name,
        // street,
        // home,
        // flat,
        email
    }
}

const checkSecondForm = () => {
    const { phoneNumber, name, email } = getSecondForm();

    (!phoneNumber || !name || !email) && $('.subscribe-form__not-all-fields').css({opacity: 1, display: 'flex'});

    return phoneNumber && name && email;
}

export default function MainForm(props) {
    const {apiData} = props.apiData;
    // const [isFirstForm, setIsFirstForm] = useState(true);
    const [isSecondStep, setIsSecondStep] = useState(false)

    const subscribeFormMobile = useMediaQuery({
        query: '(max-width: 992px)'
    });
    const [subDate, setSubDate] = useState(apiData.subscribe_form_date.split(';')[0])
    const [deliveryTime, setDeliveryTime] = useState([apiData.subscribe_form_monday.split(';')[0], apiData.subscribe_form_wednesday.split(';')[0]])

    const handleSendForm = useCallback(async () => {
        const { phoneNumber } = getSecondForm()
        const data = {
            ...getFirstForm(),
            ...getSecondForm(),
            phone: phoneNumber,
            date: typeof subDate === 'string' ? subDate : subDate.value,
            time: `Понедельник: ${typeof deliveryTime[0] === 'string' ? deliveryTime[0] : deliveryTime[0].value}, Среда: ${typeof deliveryTime[1] === 'string' ? deliveryTime[1] : deliveryTime[1].value}`
        }
        await subscribePackage(data)
    }, [subDate, deliveryTime])

    // const handleChangeSub = useCallback((value) => {
    //     setSubDate(value)
    // }, [])

    // const handleChangeMonday = useCallback((value) => {
    //     setDeliveryTime((val) => ([
    //         value,
    //         val[1]
    //     ]))
    // }, [])
    // const handleChangeWednesday = useCallback((value) => {
    //     setDeliveryTime((val) => ([
    //         val[0],
    //         value
    //     ]))
    // }, [])

    // if (!isFirstForm) {
    //     return (
    //         <div className='subscribe-form__main-block subscribe-form__main-block-second'>
    //             <Fragment>
    //                 <h5 className='subscribe-form__header'>
    //                     Персональная информация
    //                 </h5>
    //                 <YourName/>
    //                 <PhoneNumber/>
    //                 <Address/>
    //                 <DeliveryTime apiData={apiData} onChangeMon={handleChangeMonday} onChangeWed={handleChangeWednesday} valueMon={deliveryTime[0]} valueWed={deliveryTime[1]}/>
    //                 <YourComments/>
    //                 <h6 className='subscribe-form__about-delivery'>
    //                     Вы будете получать доставку в Пн и Ср вечером
    //                 </h6>
    //                 <div className='subscribe-form__subscribe-button' onClick={() => {
    //                     if (checkSecondForm()) {
    //                         handleSendForm()
    //                         $('.subscribe-form__thanks-for-subscribe').eq(0).css({display: 'flex'});
    //                         setTimeout(() => {
    //                             $('.subscribe-form__thanks-for-subscribe').eq(0).animate({opacity: 1}, 1000);
    //                         }, 200)
    //                         setTimeout(() => {
    //                             $('.subscribe-form__thanks-for-subscribe').eq(0).animate({opacity: 0}, 1000);
    //                         }, 5000);
    //                         $('.subscribe-form__not-all-fields').css({opacity: 0, display: 'none'});
    //                     } else if (subscribeFormMobile) {
    //                         setTimeout(() => {
    //                             $('.subscribe-form__not-all-fields').animate({opacity: 0}, 200);
    //                             setTimeout(() => $('.subscribe-form__not-all-fields').css({display: 'none'}), 250)
    //                         }, 3000)
    //                     }
    //                 }}>
    //                     <span>Подписаться</span>
    //                 </div>
    //             </Fragment>
    //         </div>
    //     )
    // }
    return (
        <div className='subscribe-form__main-block' id="subscribe">
            <ThanksForSubscribe isEnglish={apiData.isEnglish}/>
            <SubscribeBox
                isEnglish={apiData.isEnglish}
                show={isSecondStep}
                onClose={() => {
                    setIsSecondStep(false);
                    $('html, body, #app').css({overflowY: 'scroll'})
                }}
                onSubmit={() => {
                    if (checkSecondForm()) {
                        handleSendForm();
                        $('.thanks-for-subscribe')
                            .eq(1)
                            .css({display: 'flex'})
                            .animate({opacity: 1}, 1000);
                        $('.subscribe-form__thanks-for-subscribe').eq(0).css({display: 'flex'});
                        setTimeout(() => {
                            $('.subscribe-form__thanks-for-subscribe').eq(0).animate({opacity: 1}, 1000);
                        }, 200)
                        setTimeout(() => {
                            $('.subscribe-form__thanks-for-subscribe').eq(0).animate({opacity: 0}, 1000);
                        }, 5000);
                        $('.subscribe-form__not-all-fields').css({opacity: 0, display: 'none'});
                        setIsSecondStep(false)
                        $('html, body, #app').css({overflowY: 'scroll'})
                    } else if (subscribeFormMobile) {
                        setTimeout(() => {
                            $('.subscribe-form__not-all-fields').animate({opacity: 0}, 200);
                            setTimeout(() => $('.subscribe-form__not-all-fields').css({display: 'none'}), 250)
                        }, 3000)
                    }
                }}
            />
            <Fragment>
                {
                    !subscribeFormMobile ? <h5 className='subscribe-form__header'>
                        {!apiData.isEnglish ? 'Подписаться на программу' : 'Subscribe to the program'}
                    </h5> : <h5 className='subscribe-form__header'>
                        {!apiData.isEnglish ? 'Подписка на программу' : 'Subscribe to the program'}
                    </h5>
                }
                <Sex isEnglish={apiData.isEnglish} mark={checkedMark}/>
                <Days isEnglish={apiData.isEnglish} mark={checkedMark}/>
                <Times isEnglish={apiData.isEnglish} mark={checkedMark}/>
                <Superset isEnglish={apiData.isEnglish} mark={checkedMark}/>
                <ProgramType isEnglish={apiData.isEnglish} mark={checkedMark}/>
                {
                    !subscribeFormMobile ? <h6 className='subscribe-form__about-delivery'>
                        Вы будете получать доставку в Пн и Ср вечером
                    </h6> : <h6 className='subscribe-form__about-delivery'>
                        Вы будете получать доставку<br/>в&nbsp;Пн и&nbsp;Ср&nbsp;вечером
                    </h6>
                }
                <div className='subscribe-form__subscribe-button' onClick={() => {
                    if (checkLines()) {
                        setIsSecondStep(true)
                        $('html, body, #app').css({overflowY: 'hidden'})
                        !subscribeFormMobile
                            ? $('.subscribe-form__not-all-fields').css({opacity: 0, bottom: 10, display: 'none'})
                            : $('.subscribe-form__not-all-fields').css({opacity: 0, display: 'none'});
                    } else if (subscribeFormMobile) {
                        setTimeout(() => {
                            $('.subscribe-form__not-all-fields').animate({opacity: 0}, 200);
                            setTimeout(() => $('.subscribe-form__not-all-fields').css({display: 'none'}, 250))
                        }, 3000)
                    }
                }}>
                    <span>{!apiData.isEnglish ? 'Попасть в список первых' : 'Get on the list of the first'}</span>
                </div>
            </Fragment>
        </div>
    )
}
