import React from "react";
import $ from "jquery";
import {useMediaQuery} from "react-responsive";
import resetPositions from "../resetPositions";

export default function Dinner(props) {
    const mobileVersion = useMediaQuery({
        query: '(max-width: 1024px)'
    });
    const changeActivity = () => {
        $('.food-menu__dinner').addClass('food-menu__breakfast_active');

        $('.food-menu__breakfast').removeClass('food-menu__breakfast_active');
        $('.food-menu__lunch').removeClass('food-menu__breakfast_active');
        $('.food-menu__superset').removeClass('food-menu__breakfast_active');

        resetPositions();

        // $('.food-menu__first-image').css({opacity: 0, display: 'none'});
        // $('.food-menu__second-image').css({opacity: 0, display: 'none'});
        // $('.food-menu__third-image').css({opacity: 1, display: 'block'});
        props.onClick()
    }

    return (
        <div className={'food-menu__dinner'} onClick={changeActivity}>
            <span>{!props.isEnglish ? 'УЖИН' : 'DINNER'}</span>
        </div>
    )
}
